import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateCloudDefFormComponent } from '@biomodal-webapps/ui-components';
import { Subject, catchError, filter, of, takeUntil, tap } from 'rxjs';
import { WorkspaceService, CredentialsService, CloudDefinition } from '@biomodal-webapps/alto-service';


@Component({
  selector: 'biomodal-webapps-credentials-list',
  standalone: true,
  imports: [CommonModule, CreateCloudDefFormComponent],
  templateUrl: './credentials-list.component.html',
  styleUrl: './credentials-list.component.css',
})
export class CredentialsListComponent implements OnInit, OnDestroy {
  @Input() showText: boolean = true;
  @Input() showCreateForm: boolean = false;

  destroy$: Subject<boolean> = new Subject<boolean>();
  isLoading: boolean = true; // Loading state flag
  selectedCloudDef: CloudDefinition | null = null;
  cloudDefinitions: CloudDefinition[] = [];
  errorMessage: { error: string; message: string; success: boolean } | null = null;

  constructor(
    private workspaceService: WorkspaceService,
    private credentialsService: CredentialsService,
  ) {}


  ngOnInit(): void {
    console.log('CredentialsListComponent: ngOnInit');
    this.workspaceService.currentWorkspace$
    .pipe(
      takeUntil(this.destroy$),
      tap((workspace) => {
        if (!workspace) {
          // If the workspace is null, stop loading here
          this.isLoading = false;
        }
      }),
      filter((workspace) => workspace != null),
      tap((workspace) => {
        console.log('ConfigureWorkspaceComponent: workspace', workspace);
      }),
      tap((workspace: any) => {
        this.cloudDefinitions = workspace.cloud_definitions;
        this.isLoading = false; // Data has been fetched, loading is done
        // Automatically show the form if no cloud definitions are present
        this.showCreateForm = this.cloudDefinitions.length === 0;

      }),
      catchError((error) => {
        console.error('ConfigureWorkspaceComponent: error getting workspace', error);
        this.errorMessage = {
            error: error.error.error,
            message: error.error.message,
            success: error.error.success,
          };
        return of(null);
      })
    ).subscribe(); 


    this.selectedCloudDef = this.credentialsService.getCurrentCredentials();
  }


  onCloudDefinitionSelected(cloudDef: CloudDefinition) {
    this.selectedCloudDef = cloudDef;
    this.credentialsService.selectCredentials(cloudDef);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
