import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject, catchError, finalize, takeUntil, tap } from 'rxjs';
import { AltoCompanyService, CredentialsService, WorkspaceService } from '@biomodal-webapps/alto-service';
import { CompanyService } from '@biomodal-webapps/people';
import { _ParseAST } from '@angular/compiler';
import { HttpErrorResponse } from '@angular/common/http';

interface Provider {
  name: string;
  keys: string[];
}

// Predefined list of cloud providers
const providers: Provider[] = [
  { name: 'aws', keys: ['accessKey', 'secretKey', 'assumeRoleArn'] },
  { name: 'google', keys: ['data'] },
  // add other providers here... i.e. { name: 'azure', keys: ['data'] },
];

@Component({
  selector: 'biomodal-webapps-create-cloud-def-form',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './create-cloud-def-form.component.html',
  styleUrl: './create-cloud-def-form.component.css',
})
export class CreateCloudDefFormComponent {
  private destroyed$ = new Subject<void>();

  // Form group for handling form controls
  credentialsForm: FormGroup = this.formBuilder.group({
    name: [
      '',
      [Validators.maxLength(20), Validators.pattern(/^[a-zA-Z0-9_]*$/)],
    ],
    provider: ['', Validators.required],
  }) as FormGroup;
  // Other component state variables
  keys: string[] = [];
  providers = providers;
  label: string = '';
  tooltip: string = '';
  isLoading = false;
  successMessage = '';
  errorMessage = '';

  constructor(
    private formBuilder: FormBuilder,
    private altoCompanyService: AltoCompanyService,
    private workspaceService: WorkspaceService,
    private credentialsService: CredentialsService
    ) {}

  ngOnInit(): void {
    this.monitorNameChanges();
  }

  private monitorNameChanges(): void {
    const nameControl = this.credentialsForm.get('name');
    if (nameControl) {
      this.sanitizeInput(nameControl, this.destroyed$);
    }
  }

  sanitizeInput(control: AbstractControl, destroyed$: Subject<void>): void {
    control.valueChanges.pipe(takeUntil(destroyed$)).subscribe((value) => {
      if (value) {
        let sanitizedValue = value.replace(/ /g, '_'); // Replace spaces with underscores
        sanitizedValue = sanitizedValue.replace(/[^a-zA-Z0-9_]/g, ''); // Remove non-alphanumeric and non-underscore characters

        // if the value is greate than 20 characters, truncate it
        if (sanitizedValue.length > 20) {
          sanitizedValue = sanitizedValue.substring(0, 20);
        }

        if (value !== sanitizedValue) {
          control.setValue(sanitizedValue, { emitEvent: false });
        }
      }
    });
  }

  // Handle provider change and update form controls
  onProviderChange(event: Event) {
    const target = event.target as HTMLSelectElement;
    const providerName = target?.value;
    const provider = this.providers.find((p) => p.name === providerName);
    if (provider) {
      this.updateFormControls(provider);
    }
  }

  // Update form controls based on the selected provider
  private updateFormControls(provider: Provider) {
    this.keys = provider.keys;
    this.keys.forEach((key) => {
      if (!this.credentialsForm.contains(key)) {
        this.credentialsForm.addControl(key, new FormControl(''));
      }
    });
    this.label = provider.name === 'google' ? 'Service Account Key' : 'Data';
    this.tooltip =
      provider.name === 'google'
        ? 'This is the service account key that your IT department should give you'
        : '';
  }

  onSubmit() {
    let company = this.altoCompanyService.getCurrentCompany();
    let workspace = this.workspaceService.getCurrentWorkspace();
    const formValues = this.credentialsForm.value;

    const payload = {
      name: formValues.name,
      provider: formValues.provider,
      keys: {
        data: formValues.data,
      },
    };

    this.credentialsService.createCredentials(company.id, workspace.id, payload)
    .pipe(
      tap(() => {
        this.successMessage = 'Credentials created successfully!';
        this.errorMessage = '';
        this.credentialsForm.reset();
      }),
      catchError((error: HttpErrorResponse) => {
        this.errorMessage = 'Failed to create credentials.';
        console.error(error);
        throw error;
      }),
      finalize(() => {
        this.isLoading = false;
      })
    )
    .subscribe();
  }
}
