<!--
  Copyright (C) 2023 Cambridge Epigenetix. All rights reserved.
-->
<header
  *ngIf="!isAtRoot"
  class="navbar navbar-expand-lg navbar-light biomodal-light"
  style="background-color: white;"
>
  <div class="container">
    <a class="navbar-brand" href="/home">
      <img src="assets/dark-logo.png" alt="Logo" height="48" />
    </a>
    <!-- show company name -->
    <ul class="navbar-nav ms-auto">
      <ng-container
        *ngIf="userProfile$ | async as userProfile; else loading"
      >
      <li class="nav-item me-3">
        <a>{{ userProfile.company.name }}</a>
      </li>
      </ng-container>
    </ul>
    <button
      class="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarNav"
      aria-controls="navbarNav"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav ms-auto">
        <!-- Dynamically render nav items -->
        <li class="nav-item me-3" *ngFor="let item of navItems">
          <a class="nav-link fw-bold" (click)="navigate(item.route)" style="cursor:pointer">
            {{ item.text }}
          </a>
        </li>

        <!-- show user email in drop down for logout and profile options -->

        <li class="nav-item dropdown">
          <button
            class="btn account-button fw-bold dropdown-toggle"
            id="navbarDropdown"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <ng-container
              *ngIf="userProfile$ | async as userProfile; else loading"
            >
              {{ userProfile.email }}
            </ng-container>
          </button>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li>
              <a class="dropdown-item" routerLink="/profile">Profile</a>
            </li>
            <li>
              <hr class="dropdown-divider" />
            </li>
            <li><a class="dropdown-item" (click)="logout()">Logout</a></li>
          </ul>
          <ng-template #loading>
            <button class="btn account-button fw-bold" disabled>
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              Loading...
            </button>
          </ng-template>
        </li>
      </ul>
    </div>
  </div>
</header>
<header
  *ngIf="isAtRoot"
  [class.d-none]="isAtRoot"
  class="navbar navbar-expand-lg bg-transparent overlay"
>
  <div class="container">
    <a class="navbar-brand" routerLink="">
      <img src="assets/white-logo.png" alt="Logo" height="48" />
    </a>
    <button class="btn btn-outline-light border-0" routerLink="/home">
      Log In
    </button>
  </div>
</header>

