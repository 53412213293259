<biomodal-webapps-image-hero
  [bgImage]="'../assets/hero-3.png'"
  [title]="'Take Control of Your Pipelines'"
  [description]="
    'Welcome to the Pipeline Management Suite. Monitor ongoing processes, review past runs, and kick off new pipelines with ease. Turn the complexity of data analysis into a simple, manageable process.'
  "
></biomodal-webapps-image-hero>

<div class="container" style="margin-bottom: 50px">
  <ng-container *ngIf="showWorkspaceList">
    <biomodal-webapps-workspace-list
      mode="dropdown"
      (workspaceSelected)="onWorkspaceSelected($event)"
    ></biomodal-webapps-workspace-list>
  </ng-container>

  <div *ngIf="loading" class="overlay">
    <div class="spinner-border text-primary"></div>
  </div>

  <div *ngIf="errorMessage && !errorMessage.success" class="alert alert-danger" role="alert">
    <strong>Error:</strong> {{ errorMessage.message }}<br>
    <code>{{ errorMessage.error }}</code>
  </div>

  <ng-container *ngIf="!loading && workspace">
    <p-contextMenu #cm [model]="items" (onShow)="updateContextMenu()"></p-contextMenu>
    <p-table
      [value]="my_pipelines"
      styleClass="p-datatable-gridlines"
      [(contextMenuSelection)]="selectedPipeline"
      [contextMenu]="cm"
      [rowHover]="true"
      (onContextMenuSelect)="onContextMenuSelect($event)"
      [loading]="loading"
    >
      <ng-template pTemplate="header">
        <tr>
          <th>Run Name</th>
          <th>Submitted By</th>
          <th>Submitted On</th>
          <th>Version</th>
          <th>Pipeline</th>
          <th>Status</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-pipeline>
        <tr [pContextMenuRow]="pipeline">
          <td>{{ pipeline.runName }}</td>
          <td>{{ pipeline.submittedBy }}</td>
          <td>{{ pipeline.submittedOn | date : 'medium' }}</td>
          <td><p-tag class="version-tag">
            {{ pipeline.version }}</p-tag></td>
          <td><p-chip [ngClass]="{
            'primary-chip': pipeline.pipeline === 'Duet ModC',
            'info-chip': pipeline.pipeline !== 'Duet ModC'
          }">
            {{ pipeline.pipeline }}</p-chip></td>
          <td>
            <span
              [ngClass]="{
                'status-green': pipeline.status === 'SUCCEEDED',
                'status-spinning': pipeline.status === 'RUNNING',
                'status-red': pipeline.status === 'FAILED',
                'status-orange': pipeline.status === 'SUBMITTED',
                'status-black': pipeline.status === 'CANCELLED'
              }"
              class="status-text"
            >
              {{ pipeline.status }}
            </span>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </ng-container>
</div>
