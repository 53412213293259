import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'biomodal-webapps-step-recipe-selection',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './step-recipe-selection.component.html',
  styleUrl: './step-recipe-selection.component.css',
})
export class StepRecipeSelectionComponent {}
