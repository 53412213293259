import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

export interface ChecklistItem {
  id: string;
  label: string;
  description: string;
  checked: boolean;
}

@Component({
  selector: 'biomodal-webapps-checklist',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './checklist.component.html',
  styleUrl: './checklist.component.css',
})
export class ChecklistComponent {
  @Input() items: ChecklistItem[] = [];
  @Output() itemChange = new EventEmitter<ChecklistItem[]>(); // Emitting the entire list for simplicity

  onCheckboxChange(item: ChecklistItem, event: Event) {
    const checkbox = event.target as HTMLInputElement; 
    if (checkbox) {
      item.checked = checkbox.checked;
      this.itemChange.emit(this.items);
    }
  }

}
