import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  Router,
  RouterOutlet,
} from '@angular/router';
import {
  HeaderComponent,
  FooterComponent,
  NavItem,
  FullScreenLoadingLockComponent,
} from '@biomodal-webapps/ui-components';
import { PeopleService, UserProfile } from '@biomodal-webapps/people';
import { Observable, Subject } from 'rxjs';
import { AltoAuthOrchestrationService } from './services/auth-orchestration.service';
import { AltoOnboardingOrchestrationService } from './services/onboarding-orchestration-service';
import { NgIf } from '@angular/common';
import { OnboardingModalComponent } from './components/onboarding-modal/onboarding-modal.component';
import { GtmService } from '@biomodal-webapps/alto-service';

@Component({
  standalone: true,
  selector: 'biomodal-webapps-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  imports: [
    RouterOutlet,
    HeaderComponent,
    FooterComponent,
    FullScreenLoadingLockComponent,
    OnboardingModalComponent,
    NgIf,
  ],
})
export class AppComponent implements OnInit, OnDestroy {
  destroy$: Subject<void> = new Subject<void>();

  title = 'alto';
  isLoaded = false;

  navLinks: NavItem[] = [
    { route: 'https://biomodal.com/kb_articles/DataInterpretationGuide/', text: 'Knowledge Base' },
    { route: '/my-pipelines', text: 'My Pipeline Runs' },
    // Add more links as needed
  ];

  userProfileObservable: Observable<UserProfile | null> = this.peopleService.currentUser$;

  constructor(
    private authOrchestrationService: AltoAuthOrchestrationService, // we need to init the service
    private onboardingOrchestrationService: AltoOnboardingOrchestrationService, // we need to init the service
    private peopleService: PeopleService,
    private gtmservice: GtmService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.isLoaded = false;
    this.onboardingOrchestrationService.currentOnboardingStatus$.subscribe((isLoadingComplete) => {
      if (isLoadingComplete) {
        this.isLoaded = true;
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get isAtRoot(): boolean {
    return this.router.url === '/' || this.router.url === '/error' || this.router.url === '/forbidden';
  }
}
