import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'biomodal-webapps-text-block-centre',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './text-block-centre.component.html',
  styleUrl: './text-block-centre.component.css',
})
export class TextBlockCentreComponent {
  @Input() title!: string;
  @Input() body!: string;
  @Input() buttonText!: string;
  @Input() action!: () => void;

  executeAction() {
    if (this.action) {
      this.action();
    }
  }
}
