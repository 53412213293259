import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { SessionStorageService } from '@biomodal-webapps/alto-service';
import { ENV_CONFIG } from '@biomodal-webapps/config';
import { map, catchError, throwError, BehaviorSubject, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WorkspaceService {
  private apiUrl: string;
  private currentWorkspaceSubject = new BehaviorSubject<any | null>(null);
  public currentWorkspace$ = this.currentWorkspaceSubject.asObservable();

  constructor(
    @Inject(ENV_CONFIG) private config: { apiUrl: string },
    private http: HttpClient,
    private sessionStorageService: SessionStorageService
  ) {
    this.apiUrl = config.apiUrl;
  }

  fetchCompanyWorkspace(companyId: string, workspaceId: number | string, allow_session: boolean = true): Observable<any> {
    console.log(
      'AltoWorkspaceService: Fetching company workspace',
      companyId,
      workspaceId
    );

    if (allow_session) {
      const cachedWorkspace: any | null = this.sessionStorageService.getItem<any>(
        'cachedAltoWorkspace'
      );
      if (cachedWorkspace) {
        if (cachedWorkspace.id == workspaceId) {
          this.currentWorkspaceSubject.next(cachedWorkspace);
          return of(cachedWorkspace);
        }
      }
    }



    return this.http
      .get<{ success: boolean; message: string; data: any }>(
        `${this.apiUrl}/alto/companies/${companyId}/workspaces/${workspaceId}`
      )
      .pipe(
        map((response) => {
          // Directly handle the "not found" case without throwing an error
          console.log('AltoWorkspaceService: response', response);
          if (!response.success && response.message.includes('not found')) {
            return { notFound: true, message: response.message };
          }
          console.log(
            'AltoWorkspaceService: fetched company workspace',
            response.data
          );
          this.sessionStorageService.setItem('cachedAltoWorkspace', response.data);
          this.currentWorkspaceSubject.next(response.data);
          return response.data;
        }),
        catchError((error) => {
          console.log(
            'AltoWorkspaceService: Failed to fetch company workspace',
            error
          );
          // Handle other errors or unexpected responses
          return throwError(() => error);
        })
      );
  }

  getCurrentWorkspace(): any | null {
    if (this.currentWorkspaceSubject.getValue()) {
      console.log('AltoWorkspaceService: current workspace', this.currentWorkspaceSubject.getValue());
      return this.currentWorkspaceSubject.getValue();
    }
    const currentWorkspace = this.sessionStorageService.getItem<any>('cachedAltoWorkspace');
    if (currentWorkspace) {
      this.currentWorkspaceSubject.next(currentWorkspace);
      return currentWorkspace;
    }
    return null;
  }
}
