<biomodal-webapps-image-hero
  [bgImage]="'../assets/hero-3.png'"
  [title]="'Team Portal Dashboard'"
  [description]="'Welcome to the team portal. Manage your team and resources effectively.'"
></biomodal-webapps-image-hero>

<div class="container mt-5">

  <!-- Team Members Section -->
  <div class="row g-3 mb-4">
    <div class="col-md-4" *ngFor="let member of teamMembers">
      <div class="card h-100">
        <div class="card-body text-center">
          <h5 class="card-title">{{ member.name }}</h5>
          <p class="card-text">{{ member.role }}</p>
          <button class="btn btn-primary btn-sm">View</button>
          <button class="btn btn-secondary btn-sm">Edit Access</button>
        </div>
      </div>
    </div>
  </div>

  <!-- Hierarchical Cloud Resources Section -->
  <div class="row g-3 mb-4">
    <div class="col-12">
      <div class="card">
        <div class="card-header text-center bg-primary text-light">Cloud Resources</div>
        <div class="card-body">
          <p-treeTable [value]="data" [columns]="cols" [scrollable]="true" [tableStyle]="{ 'min-width': '50rem' }">
            <ng-template pTemplate="caption">
              <div class="text-xl font-bold">Cloud Resources</div>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th *ngFor="let col of columns">
                  {{ col.header }}
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowNode let-rowData="rowData" let-columns="columns">
              <tr [ttRow]="rowNode">
                <td *ngFor="let col of columns; let i = index; let last = last">
                  <p-treeTableToggler [rowNode]="rowNode" *ngIf="i === 0"></p-treeTableToggler>
                  {{ rowData[col.field] }}
                  <ng-container *ngIf="last">
                    <p-button icon="pi pi-search" [style]="{ 'margin-right': '.5em' }"></p-button>
                    <p-button icon="pi pi-pencil" severity="success"></p-button>
                  </ng-container>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="summary">
              <div style="text-align:left">
                <p-button icon="pi pi-refresh" label="Reload" severity="warning"></p-button>
              </div>
            </ng-template>
          </p-treeTable>
        </div>
      </div>
    </div>
  </div>

</div>
