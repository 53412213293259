import { ChangeDetectorRef, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TreeNode } from 'primeng/api';
import { ImageHeroComponent } from '@biomodal-webapps/ui-components';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { TableModule } from 'primeng/table';
import { TreeTableModule } from 'primeng/treetable';
import { DialogModule } from 'primeng/dialog';
import { data, cols } from './dummy-data';
import { Subject, filter, takeUntil } from 'rxjs';
import {
  ComputeEnvironmentsListComponent,
  CredentialsListComponent,
  FilebrowserComponent,
} from '@biomodal-webapps/ui-components';
import {
  AltoCompanyService,
  WorkspaceService,
  CredentialsService,
  ComputeEnvService,
  WorkflowService,
} from '@biomodal-webapps/alto-service';
import { RouterModule } from '@angular/router';
import { MessagesModule } from 'primeng/messages';
import { TooltipModule } from 'primeng/tooltip';
import { HttpEventType } from '@angular/common/http';


@Component({
  selector: 'biomodal-webapps-step-select-files-tree',
  standalone: true,
  imports: [
    CommonModule,
    ImageHeroComponent,
    TableModule,
    MessagesModule,
    ButtonModule,
    CardModule,
    TreeTableModule,
    RouterModule,
    ComputeEnvironmentsListComponent,
    TooltipModule,
    DialogModule,
    FilebrowserComponent
],
  templateUrl: './step-select-files-tree.component.html',
  styleUrls: ['./step-select-files-tree.component.css'],
})
export class StepSelectFilesTreeComponent {
  data: TreeNode[] = [];
  cols: any[] = [];

  destroy$: Subject<boolean> = new Subject<boolean>();
  companyId: string | null = null;
  workspace: any | null = null;
  cloudDefId: number | null = null;
  computeId: string | null = null;

  showCredentialsForm = false;
  messages: any[] = [];
  validationMessages: any[] = [];

  constructor(
    private altoCompanyService: AltoCompanyService,
    private workspaceService: WorkspaceService,
    private credentialsService: CredentialsService,
    private computeEnvService: ComputeEnvService,
    private workflowService: WorkflowService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.data = data;
    this.cols = cols;

    this.companyId = this.altoCompanyService.getCurrentCompany().id;
    this.workspace = this.workspaceService.getCurrentWorkspace();

    this.subscribeToCredentials();
    this.subscribeToCompute();

    this.setInitialTreeNodesFromCompute();

    
    this.setValidationMessages();
  }

  setInitialTreeNodesFromCompute() {
    if (this.workspace && this.workspace.cloud_definitions) {
      this.data = this.workspace.cloud_definitions.map((definition: any) => ({
        data: {
          name: definition.name,
          type: 'Cloud Project',
        },
        children: [
          {
            data: {
              name: 'Loading...',
              type: 'loading',
            },
          },
        ],
      }));
    }
  }


  subscribeToCompute() {
    this.computeEnvService.currentComputeEnv$
    .pipe(
      takeUntil(this.destroy$),
      filter((compute) => compute !== null)
    )
    .subscribe((compute) => {
      console.log('DuetLaunchSelectFilesComponent: Received compute', compute);
      this.computeId = compute!.id;
      this.checkRegionMismatch();

      console.log(
        'DuetLaunchSelectFilesComponent: ids',
        this.companyId,
        this.workspace.id,
        this.cloudDefId,
        this.computeId
      );
    });
  }


  subscribeToCredentials() {
    this.credentialsService.currentCredentials$
    .pipe(
      takeUntil(this.destroy$),
      filter((credentials) => credentials !== null)
    )
    .subscribe((credentials) => {
      console.log('DuetLaunchSelectFilesComponent: Received credentials', credentials);
      this.cloudDefId = credentials!.id;

      // when this page loads, we expected no compute to be selected
      // if it is selected, we need to check if it is in the list of available computes
      // if it is not, we need to deselect it
      let current_compute = this.computeEnvService.getCurrentCompute();

      if (current_compute) {
        if (!credentials!.compute_environments.some((env: any) => env.id === current_compute!.id!)) {
          this.computeEnvService.selectComputeEnv(null);
        }
      }
      console.log(
        'DuetLaunchSelectFilesComponent: ids',
        this.companyId,
        this.workspace.id,
        this.cloudDefId
      );
    });
  }

  onFileBrowserSelection(event: any) {
    console.log('DuetLaunchSelectFilesComponent: Received file browser selection', event);
    if (event.file.endsWith('.csv')) {
      let projectPath = event.folder ? `gs://${event.bucket}/${event.folder}` : `gs://${event.bucket}/`;
      this.workflowService.projectFolderPath = projectPath;
      this.workflowService.metaSheetName = event.file;
    }
    this.validSelection();
  }

  setValidationMessages() {
    this.validationMessages = [
      {
        severity: 'info',
        summary: 'Requirements',
        detail:
          'To proceed, ensure the following: A CSV metasheet is selected, a compute environment is selected, and the metasheet is in the same folder/bucket as the "nf-input" folder.',
      },
    ];
  }


  // TODO: Maybe move this to a service
  checkRegionMismatch() {
    // console.log('Checking region mismatch...');
    // let compute = this.computeEnvService.getCurrentCompute();
    // if (compute) {
    //   console.log('Compute:', compute);
    //   if (this.selectedBucket) {
    //     console.log('Bucket:', this.selectedBucket);
    //     ///confirm that lower case selectedBucket.data.location is the same as compute.region
    //     if (this.selectedBucket.data.location.toLowerCase() !== compute!.region.toLowerCase()) {
    //       console.log('Region mismatch');
    //       this.messages = [
    //         {
    //           severity: 'warn',
    //           summary: 'Region Mismatch',
    //           detail: `The selected bucket is in region ${this.selectedBucket.data.location.toLowerCase()} but the selected compute environment is in region ${
    //             compute.region
    //           }. This could result in high data transfer costs.`,
    //         },
    //       ];
    //     } else {
    //       console.log('Region match');
    //       this.messages = [];
    //     }
    //   } else {
    //     console.log('No bucket selected');
    //   }
    // } else {
    //   console.log('No compute selected');
    // }
  }



  validSelection(): boolean {
    const isValid =
      this.cloudDefId &&
      this.computeId &&
      this.workflowService.projectFolderPath &&
      this.workflowService.metaSheetName;

    if (isValid) {
      this.validationMessages = [];
    } else {
      this.setValidationMessages();
    }

    return !!isValid;
  }


  

}
