import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'biomodal-webapps-image-hero',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './image-hero.component.html',
  styleUrl: './image-hero.component.css',
})
export class ImageHeroComponent {
  @Input() bgImage!: string;
  @Input() title!: string;
  @Input() description!: string;
  @Input() buttonText!: string;
  @Input() action!: () => void;
  @Input() hasWavyBottom: boolean = true;
  @Input() svgIcon?: string; // New input for SVG icon

  executeAction() {
    if (this.action) {
      this.action();
    }
  }
}
