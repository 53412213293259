import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ENV_CONFIG } from '@biomodal-webapps/config';
import {
  map,
  catchError,
  throwError,
  BehaviorSubject,
  Observable,
  switchMap,
  of,
} from 'rxjs';

export interface ComputeRegion {
  id: string;
  name: string;
}

export interface ComputeEnvironment {
  credentials_id: string,
  id: string,
  name: string,
  platform: string,
  preemptible: boolean,
  region: string,
  workdir: string
}

@Injectable({
  providedIn: 'root',
})
export class ComputeEnvService {
  private apiUrl: string;
  private currentComputeEnvSubject = new BehaviorSubject<ComputeEnvironment | null>(null);
  public currentComputeEnv$ = this.currentComputeEnvSubject.asObservable();

  constructor(
    @Inject(ENV_CONFIG) private config: { apiUrl: string },
    private http: HttpClient
  ) {
    this.apiUrl = config.apiUrl;
  }

  createComputeEnv(companyId: string, workspaceId: number, clouddefId: number, payload: any) {
    return this.http
      .post<{ success: boolean; message: string; data: any }>(
        `${this.apiUrl}/alto/companies/${companyId}/workspaces/${workspaceId}/cloud-definitions/${clouddefId}/computes`,
        payload
      )
      .pipe(
        map((response) => {
          console.log(
            'AltoComputeService: created compute',
            response.data
          );
          this.currentComputeEnvSubject.next(response.data);
          return response.data;
        }),
        catchError((error) => {
          console.error(
            'AltoComputeService: failed to create compute',
            error
          );
          return throwError(() => error);
        })
      );
  }

  getComputeRegions(companyId: string): Observable<ComputeRegion[]> {
    return this.http
      .get<{ success: boolean; message: string; data: any }>(`${this.apiUrl}/alto/${companyId}/platforms/google-lifesciences/regions`)
      .pipe(
        map((response) => response.data.regions),
        catchError((error: HttpErrorResponse) => {
          console.error(error);
          return of([]); // Emit an empty array of regions
        })
      );
  }

  selectComputeEnv(computeEnv: ComputeEnvironment | null) {
    this.currentComputeEnvSubject.next(computeEnv);
  }

  getCurrentCompute(): ComputeEnvironment | null {
    return this.currentComputeEnvSubject.getValue();
  }
}
