<p>File browser works</p>
<p-treeTable
      [value]="data"
      [scrollable]="true"
      [tableStyle]="{ 'min-width': '50rem' }"
      selectionMode="single"
      (onNodeSelect)="onNodeSelect($event)"
      (onNodeExpand)="onNodeExpand($event)"
      (onNodeUnselect)="onNodeUnselect($event)"
    >
   <ng-template pTemplate="caption">
      <div class="d-flex justify-content-between align-items-center">
        <div class="text-xl font-bold">Your Cloud Projects</div>
        <div *ngIf="allowDownload" class="btn-group">
          <!-- Download Button -->
          <biomodal-webapps-file-download-button
            [companyId]="companyId"
            [workspaceId]="workspace.id"
            [bucketName]="selectedBucket"
            [selectedFileFullPath]="selectedFolder + selectedFile"
            (fileToLarge)="downloadSnippetModal.showDownloadSnippet($event)"
          ></biomodal-webapps-file-download-button>

          <biomodal-webapps-download-snippet-modal
          #downloadSnippetModal
          [bucketName]="selectedBucket"
          [selectedFileFullPath]="selectedFolder + selectedFile"
          > </biomodal-webapps-download-snippet-modal>
        </div>
      </div>
    </ng-template>

    <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
        <tr [ttRow]="rowNode" [ttSelectableRow]="rowNode">
          <td>
            <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
            <ng-container>
              <img *ngIf="rowData.type === 'Cloud Project'" src="assets/icons8-cloud.svg" alt="Cloud Project" class="icon" />
              <img *ngIf="rowData.type === 'Bucket'" src="assets/icons8-box.svg" alt="Bucket" class="icon" />
              <img *ngIf="rowData.type === 'folder'" src="assets/icons8-folder.svg" alt="Folder" class="icon" />
              <img *ngIf="rowData.type === 'file'" src="assets/icons8-file.svg" alt="File" class="icon" />
              <img *ngIf="rowData.type === 'Error'" src="assets/icon-error.svg" alt="Error" class="icon" />
              <i *ngIf="rowData.type === 'loading'" class="pi pi-spinner pi-spin"></i>

            </ng-container>
            {{ rowData.name }}
            <ng-container *ngIf="rowData.type === 'Bucket'">
              <span style="font-style: italic; font-size:smaller"> ({{ rowData.location }})</span>
            </ng-container>
          </td>
        </tr>
      </ng-template>
</p-treeTable>
