<!--
  Copyright (C) 2023 Cambridge Epigenetix. All rights reserved.
-->
<form [formGroup]="credentialsForm" (ngSubmit)="onSubmit()">
  <!-- Cloud Name -->
  <div class="form-group">
    <label>Cloud Name</label>
    <input
      type="text"
      formControlName="name"
      class="form-control"
      placeholder="Enter a custom name i.e. Our_GCP_Project"
      title="Any name you want to give to this credential config. You can have lots of these.
                                    You usually won't have more than one per cloud provider. i.e. Our_GCP_Project"
    />
  </div>

  <!-- Provider Selection -->
  <div class="form-group">
    <label>Provider</label>
    <select formControlName="provider" class="form-select" (change)="onProviderChange($event)">
      <option *ngFor="let provider of providers" [value]="provider.name">
        {{ provider.name }}
      </option>
    </select>
  </div>

  <!-- Dynamic Auth Fields (Different for each provider) -->
  <div *ngFor="let key of keys" class="form-group">
    <label [attr.title]="tooltip">{{ key === 'data' && label ? label : key }}</label>
    <textarea class="form-control" [formControlName]="key" placeholder="Enter {{ key }}"></textarea>
  </div>

  <!-- Submit Button and Loading Indicator -->
  <div class="text-right" style="margin-bottom: 20px">
    <button *ngIf="!isLoading" type="submit" class="btn btn-primary">Create</button>
    <div *ngIf="isLoading" class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>

  <!-- Success and Error Messages -->
  <div *ngIf="successMessage" class="alert alert-success" role="alert">
    {{ successMessage }}
  </div>
  <div *ngIf="errorMessage" class="alert alert-danger" role="alert">
    {{ errorMessage }}
  </div>
</form>
