import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { CredentialsService } from '@biomodal-webapps/alto-service';

@Component({
  selector: 'biomodal-webapps-file-download-button',
  standalone: true,
  imports: [CommonModule,     ButtonModule  ],
  templateUrl: './file-download-button.component.html',
  styleUrl: './file-download-button.component.css',
})
export class FileDownloadButtonComponent {
  @Input() companyId: string = '';
  @Input() workspaceId!: number;
  @Input() bucketName: string = '';  
  @Input() selectedFileFullPath: string = '';

  @Output() fileToLarge = new EventEmitter<string>();

  cloudDefId: number | undefined = undefined;
  /**
   *
   */
  constructor(
    private credentialsService: CredentialsService
  ) {
    this.credentialsService.currentCredentials$.subscribe((credentials) => {
      if (!credentials) {
        return;
      }
      console.log('FileDownloadButtonComponent: credentials', credentials);
      this.cloudDefId = credentials.id;
    }
    );
  }


  isDownloadableFile(): boolean {
    if (!this.selectedFileFullPath) {
      return false; // No file selected or multiple selection
    }
    const isSupported = this.isSupportedFile(this.selectedFileFullPath);
    return isSupported;
  }

  isSupportedFile(fileName: string): boolean {
    const supportedExtensions = ['csv', 'txt', 'json', 'pdf', 'gz', 'fasta', 'zarrz']; 
    const fileExtension = fileName.split('.').pop()?.toLowerCase();
    return supportedExtensions.includes(fileExtension || '');
  }

  downloadFile(): void {

    console.log('Download file:', this.selectedFileFullPath, 'frombucket:', this.bucketName, 'cloudDef', this.cloudDefId, 'workspace:', this.workspaceId, 'company:', this.companyId);

    this.credentialsService
      .checkFileSize(this.companyId, this.workspaceId, this.cloudDefId!, this.bucketName, this.selectedFileFullPath)
      .subscribe({
        next: (response) => {
          // Call the service to get the download URL
          const downloadUrl = this.credentialsService.downloadFileUrl(
            this.companyId,
            this.workspaceId,
            this.cloudDefId!,
            this.bucketName,
            this.selectedFileFullPath
          );

          // Trigger the native browser download with an anchor tag
          const anchor = document.createElement('a');
          anchor.href = downloadUrl;
          anchor.target = '_blank'; // Optionally, open in a new tab/window
          const file_name = this.selectedFileFullPath.split('/').pop()!; 
          anchor.download = file_name; // Set the file name for the download
          anchor.click(); // Trigger the download

          console.log(`Download started for file: ${this.selectedFileFullPath}`);
        },
        error: (error) => {
          if (error.status === 413) {
            console.error('File too large. Please use gcloud instead.');
            this.fileToLarge.emit('File too large. Please use gcloud instead.');
          } else {
            console.error('An error occurred:', error);
          }
        },
      });
  }

}
