/*
  Copyright (C) 2023 Cambridge Epigenetix. All rights reserved.
*/
import { InjectionToken } from '@angular/core';

export const ENV_CONFIG = new InjectionToken<{ apiUrl: string }>('EnvConfig');

export const sharedLocalEnvironment = {
    production: false,
    apiUrl: 'http://localhost:5000',
    apiLoginPage: 'http://localhost:5000/auth/pages/login',
    apiGoogleLoginPage: 'http://localhost:5000/auth/pages/login/google',
  };
  
export const sharedStagingEnvironment = {
    production: false,
    apiUrl: 'https://api.biomodal.dev',
    apiLoginPage: 'https://api.biomodal.dev/auth/pages/login',
    apiGoogleLoginPage: 'http://api.biomodal.dev/auth/pages/login/google',

  };

export const sharedProdEnvironment = {
  production: true,
  apiUrl: 'https://app.biomodal.com/',
  apiLoginPage: 'https://app.biomodal.com/auth/pages/login',
  apiGoogleLoginPage: 'http://app.biomodal.com/auth/pages/login/google',
};
