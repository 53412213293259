<div class="card h-100">
    <div class="card-header text-center bg-primary text-light">
      {{ title }}
      <a *ngIf="docLink" [href]="docLink" target="_blank" class="float-right">
        <i class="fas fa-info-circle" style="color: var(--lightest-grey);"></i>
      </a>
    </div>
    <div class="card-body">
        <div *ngIf="scatterChartOptions && scatterChartOptions.series">
            <apx-chart
              [series]="scatterChartOptions.series"
              [chart]="scatterChartOptions.chart!"
              [xaxis]="scatterChartOptions.xaxis!"
              [yaxis]="scatterChartOptions.yaxis!"
            ></apx-chart>
          </div>

        <p>{{ description }}</p>
      <!-- Render chart here using the data input -->

    </div>
  </div>
