import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorkspaceService, CredentialsService, ComputeEnvService, ComputeEnvironment } from '@biomodal-webapps/alto-service';
import { Subject, takeUntil, filter, tap } from 'rxjs';
import { CreateComputeEnvFormComponent } from '@biomodal-webapps/ui-components';


@Component({
    selector: 'biomodal-webapps-compute-environments-list',
    standalone: true,
    templateUrl: './compute-environments-list.component.html',
    styleUrl: './compute-environments-list.component.css',
    imports: [CommonModule, CreateComputeEnvFormComponent]
})
export class ComputeEnvironmentsListComponent implements OnInit, OnDestroy {
  @Input() showText: boolean = true;
  @Input() showCreateForm: boolean = false;

  destroy$: Subject<boolean> = new Subject<boolean>();
  isLoading: boolean = true; // Loading state flag
  selectedCompute: ComputeEnvironment | null = null;
  computeEnvironments: ComputeEnvironment[] = [];

  constructor(
    private credentialsService: CredentialsService,
    private computeService: ComputeEnvService,
  ) {}


  ngOnInit(): void {
    this.credentialsService.currentCredentials$
    .pipe(
      takeUntil(this.destroy$),
      filter((credentials) => credentials != null),
      tap((credentials) => {
        console.log('ComputeEnvironmentList: creds', credentials);
      }),
      tap((credentials: any) => {
        this.computeEnvironments = credentials.compute_environments;
        this.isLoading = false; // Data has been fetched, loading is done
        this.showCreateForm = this.computeEnvironments.length === 0;
      }
    )).subscribe(); 

    this.selectedCompute = this.computeService.getCurrentCompute();
  }

  onComputeSelected(compute: ComputeEnvironment) {
    this.selectedCompute = compute; 
    this.computeService.selectComputeEnv(compute);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }


}
