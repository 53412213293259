<!-- Show loading spinner -->
<div *ngIf="isLoading || !errorMessage ">
  <div class="spinner-border text-primary" role="status">
    <span class="sr-only text-white">Checking workspace readiness...</span>
  </div>
</div>

<div *ngIf="errorMessage && !errorMessage.success" class="alert alert-danger" role="alert">
  <strong>Error:</strong> {{ errorMessage.message }}<br>
  <code>{{ errorMessage.error }}</code>
</div>

<div *ngIf="!isLoading">
  <div *ngIf="isWorkspaceReady; else notReadyBlock">
    <div>
      <h1>Your Workspace is Set Up and Ready!</h1>
      <p>
        Hi {{loggedInUserName}}. Your workspace is fully configured to run duet. You can
        now run your data through the pipeline.
      </p>
      <p>
        If you have any questions or need help, please contact us at
        <a href="mailto:support@biomodal.com" class="text-white"
          >support&#64;biomodal.com</a
        >. Otherwise, click the button below to get started. This checklist will
        help you make sure you have everything you need to run the pipeline.
      </p>
      <h3 class="mt-1">Pre-Run Checklist</h3>
      <biomodal-webapps-checklist
        [items]="checklistItems"
        (itemChange)="handleItemChange($event)"
      ></biomodal-webapps-checklist>
      <div style="text-align: right">
        <button class="progress-button" [disabled]="!allItemsChecked" [routerLink]="['/launch-duet/select-files']">
          Start
        </button>
      </div>
    </div>
  </div>

  <ng-template #notReadyBlock>
    <p>
      Your workspace is not ready. Please
      <a [routerLink]="['/launch-duet/configure-workspace']"
        >configure your workspace</a
      >.
    </p>
  </ng-template>
</div>
