import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserProfile, BiomodalSimpleUserProfile } from '@biomodal-webapps/people';
import { AuthenticationService } from '@biomodal-webapps/auth-services';

export interface NavItem {
  route: string;
  text: string;
}

@Component({
  selector: 'biomodal-webapps-header',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.css',
})
export class HeaderComponent {

  @Input() navItems: NavItem[] = []; // Default to an empty array
  @Input() userProfile$: Observable<UserProfile | BiomodalSimpleUserProfile | null> = new BehaviorSubject(null);

  constructor(private router: Router, private authService: AuthenticationService) {}

  navigate(route: string): void {
    if (route.startsWith('http')) {
      window.open(route, '_blank');
      return;
    } else {
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
        this.router.navigate([route]));
    }
  }

  logout(): void {
    this.authService.logout();
  }

  selectTenancy(): void {
    //this.tenancyService.selectTenancy(tenancy);
  }

  get isAtRoot(): boolean {
    return this.router.url === '/';
  }

}
