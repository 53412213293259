export const LINKS = {
    DISCOVER_LINKS: {
      sectionDisplayName: 'Discover',
      DUET_EVOC: {
        display: 'duet evoC',
        link: 'https://biomodal.com/products/duet-evoc/'
      },
      DUET_MODC: {
        display: 'duet +modC',
        link: 'https://biomodal.com/products/duet-modc/'
      },
      DUET_MULTIOMICS_SOLUTION: {
        display: 'duet multiomics solution',
        link: 'https://biomodal.com/technology/'
      },
      DUET_SOFTWARE: {
        display: 'duet software',
        link: 'https://biomodal.com/technology/duet-software/'
      }
    },
  
    EXPLORE_LINKS: {
      sectionDisplayName: 'Explore',
      RESOURCES: {
        display: 'Resources',
        link: 'https://biomodal.com/resources/'
      },
      CANCER_RESEARCH: {
        display: 'Cancer research',
        link: 'https://biomodal.com/applications/cancer-research/'
      },
      NEURODEGENERATIVE_DISEASE: {
        display: 'Neurodegenerative disease',
        link: 'https://biomodal.com/applications/neurodegenerative-disease/'
      },
      AGEING: {
        display: 'Ageing',
        link: 'https://biomodal.com/applications/ageing/'
      },
      LIQUID_BIOPSY: {
        display: 'Liquid biopsy',
        link: 'https://biomodal.com/applications/liquid-biopsy/'
      },
      PRECISION_MEDICINE: {
        display: 'Precision medicine',
        link: 'https://biomodal.com/applications/precision-medicine/'
      }
    },
  
    CONNECT_LINKS: {
      sectionDisplayName: 'Connect',
      CONTACT: {
        display: 'Contact',
        link: 'https://biomodal.com/contact/'
      },
      ABOUT: {
        display: 'About',
        link: 'https://biomodal.com/about/'
      },
      DOCUMENTATION: {
        display: 'Documentation portal',
        link: 'https://biomodal.com/documentation/'
      },
      CAREERS: {
        display: 'Careers',
        link: 'https://biomodal.com/careers/'
      },
      NEWS: {
        display: 'News',
        link: 'https://biomodal.com/news/'
      },
      EVENTS: {
        display: 'Events',
        link: 'https://biomodal.com/events/'
      },
      LEADERSHIP_TEAM: {
        display: 'Leadership team',
        link: 'https://biomodal.com/team/'
      },
      CERTIFIED_SERVICE_PROVIDER: {
        display: 'Certified Service Provider',
        link: 'https://biomodal.com/certified-service-providers/life-brain/'
      }
    },
  
    LEGAL_LINKS: {
      sectionDisplayName: 'Legal',
      TERMS_OF_USE: {
        display: 'Terms of Use',
        link: 'https://biomodal.com/terms-of-use/'
      },
      PRIVACY_POLICY: {
        display: 'Privacy policy',
        link: 'https://biomodal.com/privacy-policy/'
      },
      COOKIE_POLICY: {
        display: 'Cookie policy',
        link: 'https://biomodal.com/cookie-policy/'
      },
      PATENTS: {
        display: 'Patents',
        link: 'https://biomodal.com/patents/'
      },
      TRADEMARKS: {
        display: 'Trademarks',
        link: 'https://biomodal.com/trademarks/'
      }
    }
  };
  