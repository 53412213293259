<!-- Loading Spinner -->
<div *ngIf="isLaunching" class="col-12">
  <div class="d-flex justify-content-center">
    <div
      class="spinner-border text-primary"
      style="color: white !important"
      role="status"
    >
      <span class="sr-only">Launching...</span>
    </div>
  </div>
</div>
<!-- Success Message -->
<div *ngIf="!isLaunching && isSuccess">
  <h1>Congratulations!</h1>
  <p>
    The pipeline is running. You can head over to the
    <a routerLink="/my-pipeline" style="color: var(--df4b189)"
      >Pipeline Manager</a
    >
    to view the status of past and current pipelines.
  </p>
</div>
<!-- Error  Message -->
<div *ngIf="!isLaunching && isSuccess === false">
    <h1>Oops!</h1>
    <p>
      We encountered a problem launching your pipeline. Please check your settings and try again. If the problem persists,
      reach out to our support team for assistance.
    </p>
    <p>
      Error Details: <strong>{{ errorMessage }}</strong>.
    </p>
</div>
<!-- Form -->
<div *ngIf="isLaunching === false && isSuccess === null">
  <h1>Run Pipeline</h1>
  <p>
    You’re ready to run your pipeline! This process uses the resources and files
    defined in your cloud project to execute tasks. The pipeline can involve
    several stages and may take some time to complete, depending on the
    complexity of your project.
  </p>
  <hr />
    <form [formGroup]="form" (ngSubmit)="onSubmit(model)">
    <formly-form [form]="form" [fields]="fields" [model]="model"></formly-form>
    <div style="text-align: right">
     <button type="submit" style="margin-top:1.2rem" class="progress-button" [disabled]="form.invalid">Submit</button>
    </div>
  </form>
</div>
