<div>
  <h1>Configure your workspace</h1>
  <p>
    Hi [USERNAME]. Before you can run your data through the duet pipeline you'll
    need to set up some cloud compute resources <a>(or use ours)</a>. Don't
    worry, we'll walk you through it.
  </p>

  <p>You'll want to be sure you've done the following:</p>
  <h3>1. Talk to your IT department</h3>
  <p>
    Begin by coordinating with your IT team. They can assist in setting up a
    project in your preferred cloud service (currently, we support GCP). Once
    set up, they should provide you with authentication details, such as a
    service account key or password, which youU+2019ll need for the next step.
  </p>
  <biomodal-webapps-checklist
    [items]="checklistItems"
    (itemChange)="handleItemChange($event)"
  ></biomodal-webapps-checklist>
  <br />
  <hr />
  <!-- CREATE AND SELECT CREDENTIALS -->
  <div *ngIf="isChecklistComplete">
    <h3>2. Securely Add Your Cloud Credentials</h3>
    <biomodal-webapps-credentials-list></biomodal-webapps-credentials-list>
    <br />
    <hr />
    <!-- CREATE COMPUTE ENVIRONMENT -->
    <div *ngIf="isCredentialsSelected">
      <h3>3. Create a Compute Environment</h3>
      <biomodal-webapps-compute-environments-list></biomodal-webapps-compute-environments-list>
    </div>
  </div>
</div>
