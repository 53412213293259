<div class="card">
  <div class="card-header">Your Clouds</div>
  <div *ngIf="showText" class="card-body">
    <p class="text-dark">
      To integrate our pipeline with your cloud environment, we require specific
      access credentials, like a GCP service account key or AWS access keys.
      These should have been prepared with limited permissions essential for
      running our pipeline, ensuring both security and functionality.
    </p>
    <p class="text-dark">
      <strong> Your Security, Our Priority:</strong> Your credentials are
      encrypted and securely stored, accessible only for the purpose of
      configuring and running the pipeline. We adhere to strict data protection
      standards to safeguard your information.
    </p>
  </div>
  <div *ngIf="isLoading && !errorMessage" class="list-group-item">
    Loading cloud definitions...
  </div>
  <div *ngIf="errorMessage && !errorMessage.success" class="alert alert-danger" role="alert">
    <strong>Error:</strong> {{ errorMessage.message }}<br>
    <code>{{ errorMessage.error }}</code>
  </div>

  <div *ngIf="!this.isLoading && cloudDefinitions.length == 0" class="alert alert-danger" role="alert">
    <strong>Error:</strong> Workspace is not available, cannot get or create cloud definitions. Please try reloading the browser<br>
  </div>

  <div *ngIf="!isLoading">
    <ul *ngIf="cloudDefinitions.length > 0; else noCloudDefs" class="list-group list-group-flush">
      <a
        *ngFor="let cloudDef of cloudDefinitions; let i = index"
        class="list-group-item list-group-item-action"
        [class.active]="selectedCloudDef?.id === cloudDef.id"
        (click)="onCloudDefinitionSelected(cloudDef)"
      >
        {{ cloudDef.name }}
        <button
          type="button"
          class="btn btn-outline-secondary btn-sm me-2 float-end"
        >
          Edit
        </button>
        <button type="button" class="btn btn-outline-danger btn-sm float-end">
          Delete
        </button>
      </a>
      <a class="list-group-item list-group-item-action" (click)="showCreateForm = !showCreateForm">
        You already have {{ cloudDefinitions.length }} cloud definition(s), select one to move on, or click here to create a new one
        <span class="float-end">&#x25BE;</span> 
      </a>
    </ul>
    <ng-template #noCloudDefs>
      <p>You currently have no cloud definitions set up, please use the form to create one.</p>
    </ng-template>
    <div *ngIf="showCreateForm" class="container">
      <biomodal-webapps-create-cloud-def-form></biomodal-webapps-create-cloud-def-form>
    </div> 
    <p *ngIf="cloudDefinitions.length > 0 && !showCreateForm">
      Select a cloud definition to start creating compute resources within it.
    </p>
  </div>
</div>

<!-- <div class="container">
  <div class="row" *ngFor="let cloudDef of cloudDefinitions">
    <div class="col-md-4">
      <div class="card mb-3">
        <div class="card-body" style="padding: 20px;">
          <img src="../../../assets/gcp-icon.png" alt="Logo" class="card-img-top">
          <h5 class="card-title text-truncate">{{ cloudDef.name }}</h5>
          <p class="card-text text-dark">{{ cloudDef.provider }}</p>
          <button class="btn btn-outline-secondary btn-sm">Edit</button>
          <button class="btn btn-outline-danger btn-sm">Delete</button>
        </div>
      </div>
      <button class="btn btn-primary" (click)="showCreateForm = !showCreateForm">Create New Cloud Definition</button>
    </div>
    <div class="col-md-8">
      <ul class="list-group">
        <li *ngFor="let compute of cloudDef.compute_environments" class="list-group-item d-flex justify-content-between align-items-center">
          {{ compute.name }}
          <span class="badge bg-primary rounded-pill">Select</span>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-center">
          Add New Compute
          <button class="btn btn-success btn-circle btn-sm"><i class="bi bi-plus"></i></button>
        </li>
      </ul>
    </div>
  </div>
</div> -->
