<!--
  Copyright (C) 2023 Cambridge Epigenetix. All rights reserved.
-->
<ng-container *ngIf="showModal">

<div class="modal-backdrop" *ngIf="showModal"></div>

<div
    class="modal"
    [ngClass]="{ show: showModal }"
    tabindex="-1"
    style="display: block; z-index: 1050;"
    *ngIf="showModal"
>
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Welcome!</h5>
            </div>
            <div class="modal-body">
                <p>
                    It seems like it's your first time logging in. We're initializing an
                    environment for you. This may take a few moments.
                </p>
                <biomodal-webapps-alto-onboarding-console [statusUpdates]="onboardingStatuses"></biomodal-webapps-alto-onboarding-console>
            </div>
            <div class="modal-footer">
                <p *ngIf="error">Sorry there has been an error please refresh the page to try again or contact support</p>
                <button type="button" class="btn btn-secondary" (click)="closeModal()" [disabled]="!closeModalButtonEnabled">
                    Close
                </button>
            </div>
        </div>
    </div>
</div>
</ng-container>

