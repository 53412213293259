
<ul class="checklist">
  <li class="checklist-item" *ngFor="let item of items">
    <input type="checkbox" [id]="item.id" [checked]="item.checked"
           (change)="onCheckboxChange(item, $event)" />
    <label [for]="item.id">
      <strong>{{ item.label }}</strong>: {{ item.description }}
    </label>
  </li>
</ul>
