import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UserProfile } from './models';
import { ENV_CONFIG } from '@biomodal-webapps/config';
import { SessionStorageService } from '@biomodal-webapps/alto-service';

@Injectable({
  providedIn: 'root',
})
export class PeopleService {
  private apiUrl: string;
  private currentUserSubject = new BehaviorSubject<UserProfile | null>(null);
  public currentUser$ = this.currentUserSubject.asObservable();

  constructor(
    @Inject(ENV_CONFIG) private config: { apiUrl: string },
    private http: HttpClient,
    private sessionStorageService: SessionStorageService
  ) {
    this.apiUrl = config.apiUrl;
  }

  fetchMyProfile(): Observable<UserProfile> {
    // this endpoint uses the jwt token to fetch the user profile
    // of the currently logged in user from the PeopleService (Salesforce), 
    // where the jwt encoded email acts a primary key. We return the data, but we also
    // emit it to the currentUser$ observable so that it can be subscribed to. 
    console.log('PeopleService: fetchMyProfile');
    return this.http
      .get<{ data: UserProfile }>(`${this.apiUrl}/people/profile`)
      .pipe(
        map((response) => {
          console.log(
            'PeopleService: fetchMyProfile response emitted from current user subject',
            response
          );
          this.sessionStorageService.setItem('currentUserProfile', response.data); // Cache the profile
          this.currentUserSubject.next(response.data);
          return response.data;
        }),
        catchError(error => {
          console.error('Error fetching profile', error);
          return throwError(() => error);
        })
      );
  }

  getCurrentUser(): UserProfile | null {
    return this.currentUserSubject.getValue();
  }

  emitCurrentUser(user: UserProfile): void {
    this.currentUserSubject.next(user);
  }

  clearCurrentUserProfile(): void {
    this.sessionStorageService.removeItem('currentUserProfile');
    this.currentUserSubject.next(null);
  }
}
