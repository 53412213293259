<biomodal-webapps-image-hero
  [bgImage]="'../assets/hero-3.png'"
  [title]="'Unleash the Power of duet'"
  [description]="
    'Simplify your data analysis pipeline with duet, our bespoke Nextflow pipeline. Make sense of complex biological data with ease and precision.'
  "
  [buttonText]="'Start now'"
  [action]="navigateToRunPipeline.bind(this)"
></biomodal-webapps-image-hero>

<biomodal-webapps-text-block-centre
  [title]="'Maximize the Potential of Your Data'"
  [body]="
    'Many users struggle to utilize the full power of their data due to the
    complexities of running and managing bioinformatics pipelines. duet, our
    bespoke Nextflow pipeline, simplifies the process and unlocks the full
    potential of your data. With duet, you can handle complex multiomic
    analysis in less time and without the need for extensive computational
    skills.'
  "
  [buttonText]="'Explore duets features'"
  [action]="navigateToExploreFeatures.bind(this)"
></biomodal-webapps-text-block-centre>
