import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, delay, map, switchMap, tap } from 'rxjs/operators';
import { ENV_CONFIG } from '@biomodal-webapps/config';
import { OnboardingStatus } from '@biomodal-webapps/alto-service';

@Injectable({
  providedIn: 'root',
})
export class AltoPersonOnboardingService {
  private onboardingStatusSubject =
    new BehaviorSubject<OnboardingStatus | null>(null);
  public onboardingStatus$ = this.onboardingStatusSubject.asObservable();

  private onboardingCompleteSubject = new BehaviorSubject<boolean>(false);
  public onboardingComplete$ = this.onboardingCompleteSubject.asObservable();

  private apiUrl: string;

  constructor(
    @Inject(ENV_CONFIG) private config: { apiUrl: string },
    private http: HttpClient
  ) {
    this.apiUrl = config.apiUrl;
  }

  onBoardPerson(company_crm_id: string, person_crm_id: string) {
    console.log('Starting person onboarding process...');
    return this.createAltoPerson(company_crm_id, person_crm_id).pipe(
      switchMap(() =>
         this.addPersonToPrimaryWorkspace(company_crm_id, 1, person_crm_id)
        ),
      switchMap(() =>
        this.simulateStep('configuringProfile', 2000, 'Configuring profile...')
      ),
      catchError((error) => {
        console.error('Person Onboarding process failed:', error);
        return throwError(() => error);
      })
    );
  }

  private emitStatus(status: OnboardingStatus) {
    this.onboardingStatusSubject.next(status);
  }

  private createAltoPerson(
    company_crm_id: string,
    person_crm_id: string
  ): Observable<any> {
    this.emitStatus({
      step: 'createPerson',
      status: 'running',
      message: 'Creating profile...',
    });
    let payload = { person_crm_id: person_crm_id };

    return this.http
      .post<{ success: boolean; message: string; data: any }>(
        `${this.apiUrl}/alto/companies/${company_crm_id}/people`,
        payload
      )
      .pipe(
        map((response) => {
          console.log('Profile created: EMITTING COMPLETE STEP');
          this.emitStatus({step: 'createPerson', status: 'complete', message: 'Profile created.'});
          return response.data;
        }),
        catchError((error) => {
          this.emitStatus({
            step: 'createPerson',
            status: 'failed',
            message: 'Failed to create profile.',
            error,
          });
          return throwError(() => error);
        })
      );
  }



  private addPersonToPrimaryWorkspace(company_crm_id: string, workspace_id: number, person_crm_id: string): Observable<any>
  {
    // We'll make people admin by default for now. 
    // TODO: Use data from the CRM to figure out what role to assign them
    let payload = { person_crm_id: person_crm_id, role_id: 2 }; 

    this.emitStatus({ 
      step: 'addPersonToPrimaryWorkspace', 
      status: 'running', 
      message: 'Adding person to workspace...' });

    return this.http
    .post<{ success: boolean; message: string; data: any }>(
      `${this.apiUrl}/alto/companies/${company_crm_id}/workspaces/1/people`,
      payload
    ).pipe(
      map((response) => {
        console.log('Person added to workspace: EMITTING COMPLETE STEP');
        this.emitStatus({step: 'addPersonToPrimaryWorkspace', status: 'complete', message: 'Person added to workspace.'});
        return response.data;
      }),
      catchError((error) => {
        this.emitStatus({
          step: 'addPersonToPrimaryWorkspace',
          status: 'failed',
          message: 'Failed to add person to workspace.',
          error,
        });
        return throwError(() => error);
      })
    );
  }
  

  // Utility method for simulating steps with a delay
  private simulateStep(
    step: string,
    delayMs: number,
    message: string
  ): Observable<any> {
    this.emitStatus({ step, status: 'running', message });
    return of(null).pipe(
      delay(delayMs),
      map(() => {
        this.emitStatus({
          step,
          status: 'complete',
          message: `${message} completed.`,
        });
      }),
      catchError((error) => {
        this.emitStatus({
          step,
          status: 'failed',
          message: `${message} failed.`,
          error,
        });
        return throwError(() => error);
      })
    );
  }
}
