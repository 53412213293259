import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule, NgFor } from '@angular/common';
import {
  ChecklistComponent,
  ChecklistItem,
  ComputeEnvironmentsListComponent,
  CreateCloudDefFormComponent,
  CreateComputeEnvFormComponent,
  CredentialsListComponent,
} from '@biomodal-webapps/ui-components';
import { CredentialsService, WorkspaceService } from '@biomodal-webapps/alto-service';
import { Subject, filter, takeUntil, tap } from 'rxjs';

@Component({
    selector: 'alto-configure-workspace',
    standalone: true,
    templateUrl: './step-configure-workspace.component.html',
    styleUrl: './step-configure-workspace.component.css',
    imports: [
        CommonModule,
        ChecklistComponent,
        CreateComputeEnvFormComponent,
        CredentialsListComponent,
        ComputeEnvironmentsListComponent
    ]
})
export class ConfigureWorkspaceComponent implements OnInit, OnDestroy{
  
  destroy$: Subject<boolean> = new Subject<boolean>();


  checklistItems: ChecklistItem[] = [
    {
      id: 'item1',
      label: 'Cloud Project Setup',
      description:
        'Ask IT to set up a cloud project and get the name / id of it. E.g., my-gcp-project-123.',
      checked: false,
    },
    {
      id: 'item2',
      label: 'Create a Bucket',
      description:
        'Ask IT (or do it yourself) to create a bucket for all your projects and data. Get the path of the bucket, i.e., gs://my-root-bucket.',
      checked: false,
    },
    {
      id: 'item3',
      label: 'Service Account Key',
      description:
        'Get a service account key / access details with sufficient permissions for creating and writing to storage buckets.',
      checked: false,
    },
  ];

  selectedCloudDef: any | null = null;

  constructor(
    private credentialsService: CredentialsService,
  ) {}

  ngOnInit(): void {
    this.credentialsService.currentCredentials$.pipe(
      takeUntil(this.destroy$),
      filter((creds) => creds != null),
    ).subscribe((creds) => {
      this.selectedCloudDef = creds;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  //add a method that checks if a credentials has been selected
  get isCredentialsSelected() {
    return this.selectedCloudDef !== null;
  }


  // add a method that returns true if all checklist items are checked
  get isChecklistComplete() {
    return this.checklistItems.every((item) => item.checked);
  }

  handleItemChange(updatedItems: ChecklistItem[]) {
    this.checklistItems = updatedItems;
  }

  onCreateNewCloudDefinition() {
    // Handle "create new" action, e.g., open a modal or navigate to a form
    console.log('Creating new cloud definition...');
  }

}
