<button
pButton
icon="pi pi-download"
class="p-button-primary"
(click)="downloadFile()"
[disabled]="!isDownloadableFile()"
tooltip="Download Selected File"
tooltipPosition="top"
></button>


