import { HttpClient, HttpEvent } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { SessionStorageService } from '@biomodal-webapps/alto-service';
import { ENV_CONFIG } from '@biomodal-webapps/config';
import { map, catchError, throwError, BehaviorSubject, Observable } from 'rxjs';

export interface CloudDefinition {
  id: number;
  name: string;
  provider: string;
  artifact_registry_path: string;
  compute_environments: any[]; // You can further define this type based on your actual data structure
}

export interface CloudBucket {
  name: string;
  region: string;
}

export class CloudFolder {
  constructor(public full_path: string) {}

  get name(): string {
    // Split the path by '/' and filter out empty strings, then take the last part
    const parts = this.full_path.split('/').filter((p) => p);
    return parts.length > 0 ? parts[parts.length - 1] + '/' : '';
  }

  get parent(): string {
    // Find the last occurrence of '/' and get the substring up to that point
    const lastIndex = this.full_path.lastIndexOf('/', this.full_path.length - 2);
    const value = lastIndex !== -1 ? this.full_path.substring(0, lastIndex + 1) : '';

    return value;
  }
}

export class FileBlob {
  constructor(public full_path: string) {}

  get name(): string {
    // Split the path by '/' and filter out empty strings, then take the last part
    const parts = this.full_path.split('/').filter((p) => p);
    return parts.length > 0 ? parts[parts.length - 1] : '';
  }

  get parent(): string {
    // Find the last occurrence of '/' and get the substring up to that point
    const lastIndex = this.full_path.lastIndexOf('/', this.full_path.length - 2);
    const value = lastIndex !== -1 ? this.full_path.substring(0, lastIndex + 1) : '';

    return value;
  }
}

@Injectable({
  providedIn: 'root',
})
export class CredentialsService {
  private apiUrl: string;
  private currentCredentialsSubject = new BehaviorSubject<CloudDefinition | null>(null);
  public currentCredentials$ = this.currentCredentialsSubject.asObservable();

  constructor(
    @Inject(ENV_CONFIG) private config: { apiUrl: string },
    private http: HttpClient,
    private sessionStorageService: SessionStorageService
  ) {
    this.apiUrl = config.apiUrl;
  }

  createCredentials(companyId: string, workspaceId: string, payload: any) {
    return this.http
      .post<{ success: boolean; message: string; data: any }>(
        `${this.apiUrl}/alto/companies/${companyId}/workspaces/${workspaceId}/cloud-definitions`,
        payload
      )
      .pipe(
        map((response) => {
          console.log('AltoCredentialsService: created credentials', response.data);
          this.currentCredentialsSubject.next(response.data);
          return response.data;
        }),
        catchError((error) => {
          console.error('AltoCredentialsService: failed to create credentials', error);
          return throwError(() => error);
        })
      );
  }

  selectCredentials(credentials: CloudDefinition) {
    console.log('CredentialsService: Selecting credentials', credentials);
    this.currentCredentialsSubject.next(credentials);
  }

  getCurrentCredentials(): CloudDefinition | null {
    return this.currentCredentialsSubject.getValue();
  }

  get_cloud_buckets(companyId: string, workspaceId: number, cloud_def_id: number) {
    console.log(
      'CredentialsService: Getting cloud buckets for company',
      companyId,
      'workspace',
      workspaceId,
      'cloud_def_id',
      cloud_def_id
    );

    return this.http.get<CloudBucket[]>(
      `${this.apiUrl}/alto/companies/${companyId}/workspaces/${workspaceId}/cloud-definitions/${cloud_def_id}/buckets`
    );
  }

  //check cloud def scratch dir exists in cloud project
  //endpoint returns bool
  get_cloud_folders(
    companyId: string,
    workspaceId: number,
    cloud_def_id: number,
    bucket_name: string,
    folder_name: string | null = null
  ) {
    console.log('Get Cloud Folders:', companyId, workspaceId, cloud_def_id, bucket_name);

    let url = `${this.apiUrl}/alto/companies/${companyId}/workspaces/${workspaceId}/cloud-definitions/${cloud_def_id}/buckets/${bucket_name}/folders`;

    if (folder_name) {
      url += `/${folder_name}`;
    }

    console.log('Get Cloud Folders:', url);

    return this.http
      .get<string[]>(url)
      .pipe(map((folders) => folders.map((folderPath) => new CloudFolder(folderPath))));
  }

  get_files_in_folder(
    companyId: string,
    workspaceId: number,
    cloud_def_id: number,
    bucket_name: string,
    folder_name: string | null = null
  ) {
    let url = `${this.apiUrl}/alto/companies/${companyId}/workspaces/${workspaceId}/cloud-definitions/${cloud_def_id}/buckets/${bucket_name}/files`;

    // If folder_name is provided, use the folder-specific endpoint
    if (folder_name) {
      url += `?folder_name=${folder_name}`;
    }

    console.log('Get CSVs:', companyId, workspaceId, cloud_def_id, bucket_name, folder_name);

    return this.http.get<string[]>(url).pipe(map((csvs) => csvs.map((csvPath) => new FileBlob(csvPath))));
  }

  validate_sample_prefix(
    companyId: string,
    workspaceId: number,
    cloud_def_id: number,
    bucket_name: string,
    sample_prefix: string
  ) {
    console.log('Validate Sample Prefix:', companyId, workspaceId, cloud_def_id, bucket_name, sample_prefix);

    return this.http.get<any>(
      `${this.apiUrl}/alto/companies/${companyId}/workspaces/${workspaceId}/cloud-definitions/${cloud_def_id}/buckets/${bucket_name}/validate_sample_prefix?sample_prefix=${sample_prefix}`
    );
  }

  downloadFileUrl(
    companyId: string,
    workspaceId: number,
    cloudDefId: number,
    bucketName: string,
    fullFilePath: string
  ): string {
    // Construct the full download URL
    return `${this.apiUrl}/alto/companies/${companyId}/workspaces/${workspaceId}/cloud-definitions/${cloudDefId}/buckets/${bucketName}/files/${fullFilePath}/download`;
  }

  // Method to check file size or if there is a server-side error (FileTooLargeError)
  checkFileSize(
    companyId: string,
    workspaceId: number,
    cloudDefId: number,
    bucketName: string,
    fullFilePath: string
  ): Observable<any> {
    const url = this.downloadFileUrl(companyId, workspaceId, cloudDefId, bucketName, fullFilePath);
    return this.http.head(url, { observe: 'response' }); // HEAD request to check for errors
  }
}
