import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ENV_CONFIG } from '@biomodal-webapps/config';
import { SessionStorageService } from '@biomodal-webapps/alto-service';

@Injectable({
  providedIn: 'root',
})
export class AltoPersonService {
  private apiUrl: string;
  private currentPersonSubject = new BehaviorSubject<any | null>(null);
  public currentPerson$ = this.currentPersonSubject.asObservable();

  constructor(
    @Inject(ENV_CONFIG) private config: { apiUrl: string },
    private http: HttpClient,
    private sessionStorageService: SessionStorageService
  ) {
    this.apiUrl = config.apiUrl;
  }

  fetchAltoPerson(
    company_crm_id: string,
    person_crm_id: string,
    allow_session: boolean = true
  ): Observable<any> {
    if (allow_session) {
      const cachedProfile: any | null =
        this.sessionStorageService.getItem<any>('cachedAltoPerson');
      if (cachedProfile) {
        if (cachedProfile.id == person_crm_id) {
          this.currentPersonSubject.next(cachedProfile);
          return of(cachedProfile);
        }
      }
    }
    
    return this.http
      .get<{ success: boolean; message: string; data: any }>(
        `${this.apiUrl}/alto/companies/${company_crm_id}/people/${person_crm_id}`
      )
      .pipe(
        map((response) => {
          // Directly handle the "not found" case without throwing an error
          if (!response.success && response.message.includes('not found')) {
            // Return an object that clearly indicates the company is not found, without throwing
            return { notFound: true, message: response.message };
          }
          this.sessionStorageService.setItem('cachedAltoPerson', response.data);
          this.currentPersonSubject.next(response.data);
          return response.data;
        }),
        catchError((error) => {
          console.log('AltoPersonService: Failed to fetch Person', error);
          // Handle other errors or unexpected responses
          return throwError(() => error);
        })
      );
  }

  getCurrentPerson(): Observable<any | null> {
    return this.currentPerson$;
  }
}
