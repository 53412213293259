<!--
  Copyright (C) 2023 Cambridge Epigenetix. All rights reserved.
-->
<div
    class="hero-section d-flex align-items-center justify-content-center text-white text-center"
>
    <img
        class="hero-background"
        [ngClass]="hasWavyBottom ? 'wavy-bottom' : ''"
        [src]="bgImage"
    />
    <div class="container">
        <div class="hero-content w-50">
            <!-- Using an <img> tag for SVG file path -->
            <div *ngIf="svgIcon" class="svg-icon-container">
                <img src="{{ svgIcon }}" alt="SVG Icon">
            </div>
  
            <h1 class="display-4 fw-bold">{{ title }}</h1>
            <p class="lead">{{ description }}</p>
            <a *ngIf="buttonText" class="btn hero-action-button fw-bold" (click)="executeAction()">{{
                buttonText
            }}</a>
        </div>
    </div>
</div>
