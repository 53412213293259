import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogModule } from 'primeng/dialog';
import { CloudDefinition, CredentialsService } from '@biomodal-webapps/alto-service';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'biomodal-webapps-download-snippet-modal',
  standalone: true,
  imports: [CommonModule, DialogModule, ButtonModule],
  templateUrl: './download-snippet-modal.component.html',
  styleUrl: './download-snippet-modal.component.css',
})
export class DownloadSnippetModalComponent {
  @Input() bucketName: string = '';
  @Input() selectedFileFullPath: string = '';

  snippetDialogVisible: boolean = false;
  windowsSnippet: string = '';
  macSnippet: string = '';
  optionalMessage: string = '';

  constructor(
    private credentialsService: CredentialsService,
  ) {}

  isDownloadableFile(): boolean {
    if (!this.selectedFileFullPath) {
      return false; // No file selected or multiple selection
    }
    const isSupported = this.isSupportedFile(this.selectedFileFullPath);
    return isSupported;
  }

  isSupportedFile(fileName: string): boolean {
    const supportedExtensions = ['csv', 'txt', 'json', 'pdf', 'gz', 'fasta', 'zarrz']; 
    const fileExtension = fileName.split('.').pop()?.toLowerCase();
    return supportedExtensions.includes(fileExtension || '');
  }
  

  showDownloadSnippet(optionalMessage: string | null): void {
    let credentials = this.credentialsService.getCurrentCredentials();
    console.log('Snippet knows about creds', credentials);
    if (credentials!.provider === 'google') {
      this.windowsSnippet = `gcloud auth login && gcloud storage cp gs://${this.bucketName}/${this.selectedFileFullPath} C:\\path\\to\\destination`;
      this.macSnippet = `gcloud auth login && gcloud storage cp gs://${this.bucketName}/${this.selectedFileFullPath} /path/to/destination`;
    }
    //TODO: Add snippets for other providers
    if (optionalMessage) {
      this.optionalMessage = optionalMessage;
    } else {
      this.optionalMessage = '';
    }
    this.snippetDialogVisible = true;
  }

  copyToClipboard(platform: string) {
    let snippetElement;

    if (platform === 'windows') {
      snippetElement = document.getElementById('windows-snippet');
    } else if (platform === 'mac') {
      snippetElement = document.getElementById('mac-snippet');
    }
  
    if (snippetElement) {
      const textToCopy = snippetElement.innerText; // Get the text from the code block
      navigator.clipboard.writeText(textToCopy).then(() => {
        console.log('Text copied to clipboard!');
      }).catch(err => {
        console.error('Could not copy text: ', err);
      });
    } else {
      console.error('Snippet element not found');
    }
  }

}
