<h1>Select a mode</h1>
<div class="container">
  <div class="row">
    <div class="col">
      <div class="card mb-2">
        <div class="card-body p-4"> 
          <div class="row">
            <!-- details -->
            <div class="col-9">
              <h6 class="card-title text-truncate mb-1">Functional QC DuetModC 1.7.1</h6> 
              <p class="card-text text-dark" style="font-size: 0.9rem"> 
                pipeline: DuetEvoC <br>
                version: 1.7.1 <br>
                description: This pipeline performs functional QC on a DuetModC dataset
              </p>
            </div>
            <!-- Action buttons -->
            <div class="col-3 d-flex align-items-start justify-content-end">
              <button class="btn btn-primary btn-sm me-2">Use this mode</button> 
              <button class="btn btn-outline-danger btn-sm">
                <i class="bi bi-trash"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
