import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageHeroComponent } from '@biomodal-webapps/ui-components';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { TreeNode } from 'primeng/api';
import { TreeTableModule } from 'primeng/treetable';

@Component({
  selector: 'biomodal-webapps-team-portal',
  standalone: true,
  templateUrl: './team-portal.component.html',
  styleUrls: ['./team-portal.component.css'],
  imports: [CommonModule, ImageHeroComponent, TableModule, ButtonModule, CardModule, TreeTableModule]
})
export class TeamPortalComponent implements OnInit {
  teamMembers = [
    { name: 'John Doe', role: 'Project Manager' },
    { name: 'Jane Smith', role: 'Developer' },
    { name: 'Alice Johnson', role: 'Designer' }
  ];

  data: TreeNode[] = [];
  cols: any[] = [];

  ngOnInit() {
    this.data = [
      {
        data: { name: 'Workspace 1', type: 'Workspace' },
        children: [
          {
            data: { name: 'Cloud Definition 1', type: 'Cloud Definition' },
            children: [
              { data: { name: 'Compute Environment 1', type: 'Compute Environment' } },
              { data: { name: 'Compute Environment 2', type: 'Compute Environment' } }
            ]
          },
          {
            data: { name: 'Cloud Definition 2', type: 'Cloud Definition' },
            children: [
              { data: { name: 'Compute Environment 3', type: 'Compute Environment' } }
            ]
          }
        ]
      },
      {
        data: { name: 'Workspace 2', type: 'Workspace' },
        children: [
          {
            data: { name: 'Cloud Definition 3', type: 'Cloud Definition' },
            children: [
              { data: { name: 'Compute Environment 4', type: 'Compute Environment' } }
            ]
          }
        ]
      }
    ];

    this.cols = [
      { field: 'name', header: 'Name' },
      { field: 'type', header: 'Type' },
      { field: 'actions', header: 'Actions' }
    ];
  }
}
