import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ImageHeroComponent,
  TextBlockCentreComponent,
  AltoOnboardingConsoleComponent,
} from '@biomodal-webapps/ui-components';
import { Router } from '@angular/router';
import {
  AltoCompanyService,
  AltoCompanyOnboardingService,
  OnboardingStatus,
  AltoPersonOnboardingService,
  AltoPersonService,
} from '@biomodal-webapps/alto-service';
import { PeopleService } from '@biomodal-webapps/people';
import {
  EMPTY,
  Subject,
  Subscription,
  catchError,
  filter,
  map,
  of,
  switchMap,
  take,
  takeUntil,
  tap,
  throwError,
} from 'rxjs';
import { PearsonComponent } from '../workflow/tiles/pearson/pearson.component';
import { ThreeDScatterComponent } from "../workflow/tiles/3dscatter/3d-scatter.component";
;

@Component({
    selector: 'alto-home',
    standalone: true,
    templateUrl: './home.component.html',
    styleUrl: './home.component.css',
    imports: [
        CommonModule,
        ImageHeroComponent,
        TextBlockCentreComponent,
        AltoOnboardingConsoleComponent,
        PearsonComponent,
        ThreeDScatterComponent
    ]
})
export class HomeComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  showModal: boolean = false;
  isLoading: boolean = false;
  closeModalButtonEnabled = false;
  onboardingStatuses: OnboardingStatus[] = [];

  constructor(
    private router: Router,

  ) {
    //this.navigateToRunPipeline = this.navigateToRunPipeline.bind(this);
  }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
    // Signal all subscriptions to complete
    this.destroy$.next();
    this.destroy$.complete();
  }

  closeModal(): void {
    this.showModal = false;
  }

  navigateToRunPipeline = () => {
    this.router.navigate(['/launch-duet']);
  };

  navigateToExploreFeatures = () => {
    window.open('https://biomodal.com/technology/', '_blank');
  };
}
