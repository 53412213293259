import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ReactiveFormsModule, FormGroup, FormBuilder, Validators, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Subject, takeUntil, tap, take, catchError, finalize, of, switchMap, filter } from 'rxjs';
import { AltoCompanyService, ComputeEnvService, CredentialsService, WorkspaceService } from '@biomodal-webapps/alto-service';

export interface ComputeRegion {
  id: string;
  name: string;
}

@Component({
  selector: 'biomodal-webapps-create-compute-env-form',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './create-compute-env-form.component.html',
  styleUrl: './create-compute-env-form.component.css',
})
export class CreateComputeEnvFormComponent {

  private destroyed$ = new Subject<void>();

  regions: ComputeRegion[] = [];
  selectedRegion: ComputeRegion | null = null;

  // Form Groups and Controls
  computeForm!: FormGroup;

  preemptible = true;
  isCostEffective = true;

  isLoading = false;
  successMessage = '';
  errorMessage = '';

  bucketSuggestions: string[] = []; // to store the list of buckets



  constructor(
    private fb: FormBuilder,
    private altoCompanyService: AltoCompanyService,
    private workspaceService: WorkspaceService,
    private credentialsService: CredentialsService,
    private computeService: ComputeEnvService
  ) {}

  ngOnInit(): void {
    this.initializeRegions();
    this.initializeForm();
    this.credentialsService.currentCredentials$
    .pipe(
      takeUntil(this.destroyed$),
      filter((credentials) => credentials != null),
      tap((credentials) => {
        console.log('CreateComputeForm: Credentials changed', credentials);
        this.getBuckets();
      })
    ).subscribe();
  }

  private initializeRegions(): void {
    // whenever the company changes, get the regions
    // this is a bit redundant, because regions shouldnt change
    // but each company uses a unique tower API instance, so we 
    // should retrieve the regions from the company's tower API
    this.altoCompanyService.currentCompany$
      .pipe(
        takeUntil(this.destroyed$),
        filter((company) => company !== null),
        switchMap((company) => {
          console.log('CreateComputeForm: Getting regions for company', company);
            return this.computeService.getComputeRegions(company.id);
        })
      )
      .subscribe((regions: any) => {
        console.log('CreateComputeForm: Got regions', regions);
        this.regions = regions;
      });

  }
  

  private initializeForm(): void {
    this.computeForm = this.fb.group({
      computeName: [
        '',
        [
          Validators.maxLength(100),
          Validators.minLength(3),
          Validators.pattern(/^[a-zA-Z0-9_]*$/),
          Validators.required,
        ],
      ],
      region: [null, Validators.required],
      workDir: ['', [Validators.required]], //initially no validators
      preemptible: [this.preemptible],
    });

    this.monitorComputeNameChanges();
  }

  private monitorComputeNameChanges(): void {
    const nameControl = this.computeForm.get('computeName');
    if (nameControl) {
      this.sanitizeInput(nameControl, this.destroyed$);
    }
  }

  sanitizeInput(control: AbstractControl, destroyed$: Subject<void>): void {
    control.valueChanges.pipe(takeUntil(destroyed$)).subscribe((value) => {
      if (value) {
        let sanitizedValue = value.replace(/ /g, '_'); // Replace spaces with underscores
        sanitizedValue = sanitizedValue.replace(/[^a-zA-Z0-9_]/g, ''); // Remove non-alphanumeric and non-underscore characters

        // if the value is greate than 20 characters, truncate it
        if (sanitizedValue.length > 20) {
          sanitizedValue = sanitizedValue.substring(0, 20);
        }


        if (value !== sanitizedValue) {
          control.setValue(sanitizedValue, { emitEvent: false });
        }
      }
    });
  }

  allRequiredToCreateChecked(): boolean {
    return this.computeForm.valid;
  }

  onRegionChange(event: any): void {
    this.selectedRegion =
      this.regions.find((region) => region.id === event.target.value) || null;
  }

  // Handle form submission
  onSubmit() {
    if (this.computeForm.valid) {
      this.createEnvironment();
    } else {
      this.errorMessage = 'Form is invalid';
      console.error('Form is invalid');
    }
  }

  createEnvironment(): void {
    this.isLoading = true;
    const payload = {
      name: this.computeForm.get('computeName')?.value || 'DefaultName',
      region: this.selectedRegion?.id,
      workDir: this.computeForm.get('workDir')?.value || '',
      isCostEffective: this.isCostEffective,
    };
    const companyId = this.altoCompanyService.getCurrentCompany().id;
    const workspaceId = this.workspaceService.getCurrentWorkspace().id;
    const cloudDefId = this.credentialsService.getCurrentCredentials()!.id;

    this.computeService
      .createComputeEnv(companyId, workspaceId, cloudDefId, payload)
      .pipe(
        tap(() => {
          this.successMessage = 'Credentials created successfully!';
          this.errorMessage = '';
          this.computeForm.reset();
        }),
        catchError((error: HttpErrorResponse) => {
          this.errorMessage = 'Failed to create compute.';
          console.error(error);
          throw error;
        }),
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe();
  }

  toggleCostPerformance(): void {
    this.isCostEffective = !this.isCostEffective;
    this.computeForm.get('preemptible')!.setValue(this.isCostEffective);
  }

  setOptimization(isCostEffective: boolean): void {
    this.isCostEffective = isCostEffective;
  }

  getBuckets() {
    const companyId = this.altoCompanyService.getCurrentCompany().id;
    const workspaceId = this.workspaceService.getCurrentWorkspace().id;
    const cloudDefId = this.credentialsService.getCurrentCredentials()!.id;
    this.credentialsService
      .get_cloud_buckets(companyId, workspaceId, cloudDefId)
      .pipe(
        tap((buckets) => {
          console.log('CreateComputeForm: Got buckets', buckets);
          this.bucketSuggestions = buckets.map((bucket: any) => 'gs://' + bucket.name);
          //update the validator in the form with the bucket names
          const workDirFormControl = this.computeForm.get('workDir');
          if (workDirFormControl) {
            console.log('CreateComputeForm: Updating validator');
            workDirFormControl.setValidators([
              Validators.required,
              this.nameInListValidator(this.bucketSuggestions),
            ]);
            workDirFormControl.updateValueAndValidity(); // update value and re-check validity
          }

        }),
        catchError((error: HttpErrorResponse) => {
          console.error('CreateComputeForm: Failed to get buckets', error);
          return of([]);
        })
      )
      .subscribe();

  }

  nameInListValidator(stringlist: string[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (stringlist.includes(value)) {
        return null; // valid
      } else {
        return { invalidName: true }; // invalid
      }
    };
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

}
