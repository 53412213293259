<h1 *ngIf="mode === 'list'">Choose a workspace</h1>
<ng-container *ngIf="workspaces.length > 0; else noWorkspaces">
  <div *ngIf="mode === 'list'" class="list-group list-group-flush">
    <a
      *ngFor="let workspace of workspaces"
      class="list-group-item list-group-item-action"
      [class.active]="selectedWorkspace?.name === workspace.name"
      (click)="onWorkspaceSelected(workspace)"
    >
      {{ workspace.name }}
      <button
        type="button"
        class="btn btn-outline-secondary btn-sm me-2 float-end disabled"
        (click)="onEdit(workspace)"
      >
        Edit
      </button>
      <button
        type="button"
        class="btn btn-outline-danger btn-sm float-end disabled"
        (click)="onDelete(workspace)"
      >
        Delete
      </button>
    </a>
  </div>

  <div *ngIf="mode === 'dropdown'">
    <label for="workspaceDropdown">Select a workspace:</label>
    <select id="workspaceDropdown" class="form-select" (change)="onDropdownSelected($event)">
      <option value="">Select a workspace</option>
      <option *ngFor="let workspace of workspaces" value="{{workspace.id}}">{{ workspace.name }}</option>
    </select>
  </div>
</ng-container>

<ng-template #noWorkspaces>
  <p>You currently have no workspaces set up, please contact support.</p>
</ng-template>
