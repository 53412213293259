<div class="card">
  <div class="card-header">
    Your Compute Environments (for the selected cloud definition)
  </div>
  <div *ngIf="showText" class="card-body">
    <p class="text-dark">
      To optimize the performance of our pipeline, we require you to set up
      compute environments within your cloud infrastructure. This includes
      specifying the type of compute resources, configuring network settings,
      and defining the regions where your jobs will run.
    </p>
    <p class="text-dark">
      <strong>Data Residency Compliance:</strong> When configuring your compute
      environments, please ensure that you select regions that comply with data
      residency laws applicable to medical data. For example, certain
      regulations such as HIPAA in the United States or GDPR in the European
      Union may dictate that medical data not leave the borders of a specific
      jurisdiction. Our platform allows you to maintain compliance with these
      laws by selecting the appropriate region settings for your compute
      resources.
    </p>
    <p class="text-dark">
      Your compute environments are crucial for maintaining not just
      performance, but also for ensuring that legal standards for data
      protection and privacy are upheld. We are committed to providing you with
      the necessary tools and support to achieve this.
    </p>
  </div>
  <div *ngIf="isLoading" class="list-group-item">
    Loading cloud definitions...
  </div>
  <div *ngIf="!isLoading">
    <ul *ngIf="computeEnvironments.length > 0; else noComputeEnvs" class="list-group list-group-flush">
      <a
        *ngFor="let compute of computeEnvironments; let i = index"
        class="list-group-item list-group-item-action"
        [class.active]="selectedCompute?.name === compute.name"
        (click)="onComputeSelected(compute)"
      >
        {{ compute.name }}
        <button
          type="button"
          class="btn btn-outline-secondary btn-sm me-2 float-end"
        >
          Edit
        </button>
        <button type="button" class="btn btn-outline-danger btn-sm float-end">
          Delete
        </button>
      </a>
      <a class="list-group-item list-group-item-action" (click)="showCreateForm = !showCreateForm">
        You already have {{ computeEnvironments.length }} compute environment(s), select one to move on, or click here to create a new one
        <span class="float-end">&#x25BE;</span> <!-- Downward chevron -->
      </a>
    </ul>
    <ng-template #noComputeEnvs>
      <p>You currently have no cloud definitions set up, please use the form to create one.</p>
    </ng-template>
    <div *ngIf="showCreateForm" class="container">
        <biomodal-webapps-create-compute-env-form></biomodal-webapps-create-compute-env-form>
    </div>
    <p *ngIf="computeEnvironments.length > 0 && !showCreateForm">
      Select a computeEnvironment to continue.
    </p>
  </div>
</div>
