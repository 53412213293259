import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ENV_CONFIG } from '@biomodal-webapps/config';
import { Observable, map, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ReportsService {
  private apiUrl: string;

  constructor(
    @Inject(ENV_CONFIG) private config: { apiUrl: string },
    private http: HttpClient
  ) {
    this.apiUrl = config.apiUrl;
  }

  downloadReport(
    company_crm_id: string,
    workspace_id: number,
    run_id: string,
    basePath: string,
    report: any
  ): Observable<any> {
    const payload = {
      base_path: basePath,
      ...report,
    };

    console.log('Download report:', payload);

    // Note the use of the full object with next, error, and complete blocks.
    return this.http.post(
      `${this.apiUrl}/alto/companies/${company_crm_id}/workspaces/${workspace_id}/runs/${run_id}/reports`,
      payload,
      {
        responseType: 'blob', // Corrected responseType to 'blob'
      }
    );
  }

  getKeyMetrics(
    company_crm_id: string,
    workspace_id: number,
    run_id: string
  ): Observable<any> {
    return this.http.get(
      `${this.apiUrl}/alto/companies/${company_crm_id}/workspaces/${workspace_id}/runs/${run_id}/key-metrics`
    );
  }

  //get json from {{base_url}}/alto/pearson-test
  getPearsonTest(): Observable<any> {
    return this.http.get<{ success: boolean; message: string; data: any }>(`${this.apiUrl}/alto/pearson-test`).pipe(
      map((response) => response.data)
    );
  }
}
