import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ENV_CONFIG } from '@biomodal-webapps/config';
import { SessionStorageService } from '@biomodal-webapps/alto-service';

@Injectable({
  providedIn: 'root',
})
export class AltoCompanyService {
  private apiUrl: string;
  private currentCompanySubject = new BehaviorSubject<any | null>(null);
  public currentCompany$ = this.currentCompanySubject.asObservable();

  constructor(
    @Inject(ENV_CONFIG) private config: { apiUrl: string },
    private http: HttpClient,
    private sessionStorageService: SessionStorageService
  ) {
    this.apiUrl = config.apiUrl;
  }

  fetchAltoCompany(
    company_crm_id: string,
    allow_session: boolean = true
  ): Observable<any> {
    if (allow_session) {
      const cachedProfile: any | null =
        this.sessionStorageService.getItem<any>('cachedAltoCompany');
      if (cachedProfile) {
        if (cachedProfile.id == company_crm_id) {
          this.currentCompanySubject.next(cachedProfile);
          return of(cachedProfile);
        }
      }
    }

    return this.http
      .get<{ success: boolean; message: string; data: any }>(
        `${this.apiUrl}/alto/companies/${company_crm_id}`
      )
      .pipe(
        map((response) => {
          // Directly handle the "not found" case without throwing an error
          if (!response.success && response.message.includes('not found')) {
            // Return an object that clearly indicates the company is not found, without throwing
            return { notFound: true, message: response.message };
          }
          this.sessionStorageService.setItem('cachedAltoCompany', response.data);
          this.currentCompanySubject.next(response.data);
          return response.data;
        }),
        catchError((error) => {
          console.log('AltoCompanyService: Failed to fetch company', error);
          // Handle other errors or unexpected responses
          return throwError(() => error);
        })
      );
  }

  getCurrentCompany(): any | null {
    if (this.currentCompanySubject.getValue() !== null) {
      return this.currentCompanySubject.getValue();
    }
    const currentCompany = this.sessionStorageService.getItem<any>('cachedAltoCompany');
    if (currentCompany) {
      this.currentCompanySubject.next(currentCompany);
      return currentCompany;
    }
    return null;
  }

  clearCurrentCompany(): void {
    this.sessionStorageService.removeItem('currentCompany');
    this.currentCompanySubject.next(null);
  }
}
