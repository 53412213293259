import { ApplicationConfig, importProvidersFrom, } from '@angular/core';
import { provideRouter } from '@angular/router';
import { appRoutes } from './app.routes';
import { authInterceptor } from '@biomodal-webapps/auth-services';
import { environment } from '../environments/environment';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { ENV_CONFIG } from '@biomodal-webapps/config';
import { FormlyModule } from '@ngx-formly/core';
import { AccordionSectionComponent, AccordionWrapperComponent, FormlyFieldCustomTemplateComponent, FormlyFieldReferenceGenome, FormlyHeaderedSectionWrapperComponent, TileSelectionTypeComponent, ToggleSwitchTypeComponent } from '@biomodal-webapps/ui-components';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; 

export const appConfig: ApplicationConfig = {
  providers: [
    {
      provide: ENV_CONFIG,
      useValue: { apiUrl: environment.apiUrl }
    },
    provideHttpClient(
      withInterceptors([authInterceptor])
    ),
    provideRouter(appRoutes),
    importProvidersFrom([
      BrowserAnimationsModule,
      FormlyModule.forRoot({
        types: [
          { name: 'acc-item', component: AccordionWrapperComponent },
          { name: 'acc-section', component: AccordionSectionComponent },
          { name: 'customTemplate', component: FormlyFieldCustomTemplateComponent },
          { name: 'headered-section', component: FormlyHeaderedSectionWrapperComponent},
          { name: 'toggle', component: ToggleSwitchTypeComponent},
          { name: 'tile-select', component: TileSelectionTypeComponent },
          { name: 'ref-genome-select', component: FormlyFieldReferenceGenome}
          // Add other custom types and configurations as needed
        ],
      }),
    ]),
  ],
};
