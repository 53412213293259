import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'biomodal-webapps-full-screen-loading-lock',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './full-screen-loading-lock.component.html',
  styleUrl: './full-screen-loading-lock.component.css',
})
export class FullScreenLoadingLockComponent {}
