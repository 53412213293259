import { inject } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service'; // Adjust the path as necessary

export const AuthGuard: () => Observable<boolean | UrlTree> = () => {
  const authService = inject(AuthenticationService);
  const router = inject(Router);
  console.log('guard: verifying auth status');
  return authService.verifyAuthStatus().pipe(
    map((isLoggedIn) => {
      console.log('guard: is logged in:', isLoggedIn);
      if (!isLoggedIn) {
        return router.parseUrl('');
      }
      return isLoggedIn;
    })
  );
};
