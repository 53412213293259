import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
  })
  export class LocalStorageService {
    constructor() {}
  
    setItem(key: string, value: any): void {
      const jsonValue = JSON.stringify(value);
      localStorage.setItem(key, jsonValue);
    }
  
    getItem<T>(key: string): T | null {
      const item = localStorage.getItem(key);
      if (item) {
        return JSON.parse(item) as T;
      }
      return null;
    }
  
    removeItem(key: string): void {
      localStorage.removeItem(key);
    }
  
    clear(): void {
      localStorage.clear();
    }
  }
  

  @Injectable({
    providedIn: 'root'
  })
  export class SessionStorageService {
    setItem(key: string, value: any): void {
      sessionStorage.setItem(key, JSON.stringify(value));
    }
  
    getItem<T>(key: string): T | null {
      const item = sessionStorage.getItem(key);
      return item ? JSON.parse(item) : null;
    }
  
    removeItem(key: string): void {
      sessionStorage.removeItem(key);
    }
  
    clear(): void {
      sessionStorage.clear();
    }
  }
