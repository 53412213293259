import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CloudBucket } from '@biomodal-webapps/alto-service';
import { ENV_CONFIG } from '@biomodal-webapps/config';
import { Observable, tap } from 'rxjs';

// Assuming your constants are defined as strings somewhere
const dynamicKeys = {};

type DynamicPartOfPipelineParams = typeof dynamicKeys;


export interface AltoWorkflowRequest {
  meta_data: {
    cloudDefinitionId: number;
    computeEnvId: string;
    workDir: string;
    pipeline_type_id: number;
    pipeline_version_id: number;
    num_reads: number;
    resume: boolean;
    pipeline_params: DynamicPartOfPipelineParams;
  };
}


@Injectable({
  providedIn: 'root'
})
export class WorkflowService {
  private apiUrl: string;

  public selectedBucket: string | null = null;
  public projectFolderPath: string | null = null;
  public metaSheetName: string | null = null;

  constructor(
    @Inject(ENV_CONFIG) private config: { apiUrl: string },
    private http: HttpClient) { 
    this.apiUrl = config.apiUrl;
    }

  launchWorkflow(company_crm_id: string, workspace_id: string, cloud_def_id: string, request: AltoWorkflowRequest): Observable<any> {
    return this.http.post(`${this.apiUrl}/alto/companies/${company_crm_id}/workspaces/${workspace_id}/cloud-definitions/${cloud_def_id}/launch`, request).pipe(
      //tap the response and throw an error if the response is not successful
      tap(response => {
        console.log('WorkflowService: Launched workflow', response);
      })
    );
  }

  get_workflows_for_workspace(company_crm_id: string, workspace_id: string): Observable<any> {
    console.log('WorkflowService: Getting workflows for workspace', company_crm_id, workspace_id);
    return this.http.get(`${this.apiUrl}/alto/companies/${company_crm_id}/workspaces/${workspace_id}/runs`).pipe(
      tap(response => {
        console.log('WorkflowService: Got workflows for workspace', response);
      })
    );
  }

  get_verbose_workflow(company_crm_id: string, workspace_id: string, run_id: string): Observable<any> {
    console.log('WorkflowService: Getting verbose workflow', company_crm_id, workspace_id, run_id);
    return this.http.get(`${this.apiUrl}/alto/companies/${company_crm_id}/workspaces/${workspace_id}/runs/${run_id}`).pipe(
      tap(response => {
        console.log('WorkflowService: Got verbose workflow', response);
      })
    );
  }

}
