import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageHeroComponent } from '@biomodal-webapps/ui-components';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { FilebrowserComponent } from '@biomodal-webapps/ui-components';
import { NgApexchartsModule } from 'ng-apexcharts';
import {
  AltoCompanyService,
  CredentialsService,
  LocalStorageService,
  ReportsService,
  WorkflowService,
  WorkspaceService,
} from '@biomodal-webapps/alto-service';
import { FormsModule } from '@angular/forms';
import { ThreeDScatterComponent } from "./tiles/3dscatter/3d-scatter.component";
import { PearsonComponent } from "./tiles/pearson/pearson.component";
import { QcScatterPlotComponent } from "./tiles/qc-scatter-plot/qc-scatter-plot.component";
import { ListboxModule } from 'primeng/listbox';
import { MultiSelectModule } from 'primeng/multiselect';
import { GenericScatterComponent } from './tiles/generic-scatter/generic-scatter.component';
import { ChangeDetectorRef } from '@angular/core';
import { ChipModule } from 'primeng/chip';
import { TabViewModule } from 'primeng/tabview';


@Component({
    selector: 'biomodal-webapps-workflow-page',
    standalone: true,
    templateUrl: './workflow-page.component.html',
    styleUrl: './workflow-page.component.css',
    imports: [CommonModule, ImageHeroComponent, TabViewModule, MultiSelectModule, ChipModule, FilebrowserComponent, NgApexchartsModule, FormsModule, ThreeDScatterComponent, PearsonComponent, QcScatterPlotComponent, GenericScatterComponent]
})
export class WorkflowPageComponent implements OnInit, OnDestroy {
  workflowId: string | null = null;
  private routeSub: Subscription | null = null;

  public workflow_data: any;
  public reports: any[] = [];

  companyId: string | null = null;
  workspaceId: number | null = null;
  cloudDefId: number | null = null;
  computeId: number | null = null;
  dataPath: string | null = null;

  metricsResponseData: any;
  groupedMetrics: any[] = [];
  selectedMetrics: any[] = [];

  key_run_params: any[] = [];
  loadingHTMLReport: boolean = false;

  failedToGetMetrics: boolean = false;


  constructor(
    private route: ActivatedRoute,
    private altoCompanyService: AltoCompanyService,
    private altoWorkspaceService: WorkspaceService,
    private workflowService: WorkflowService,
    private cloudDefinitionService: CredentialsService,
    private reportsService: ReportsService,
    private localStorageService: LocalStorageService,
    private cdr: ChangeDetectorRef

  ) {}

  ngOnInit(): void {
    // Subscribe to paramMap to get the latest value of the route parameter
    this.routeSub = this.route.paramMap.subscribe((params) => {
      this.workflowId = params.get('workflowId');
      console.log('WorkflowPageComponent: Loading workflow', this.workflowId);
      let company = this.altoCompanyService.getCurrentCompany();
      let workspace = this.altoWorkspaceService.getCurrentWorkspace();

      this.companyId = company.id;
      this.workspaceId = workspace.id;

      console.log('WorkflowPageComponent: Company', company);
      console.log('WorkflowPageComponent: Workspace', workspace);
      //console.log('WorkflowPageComponent: Cloud Definition', cloudDef);

      // Load the workflow data
      this.workflowService
        .get_verbose_workflow(company.id, workspace.id, this.workflowId!)
        .subscribe((workflow) => {
          console.log('WorkflowPageComponent: Workflow loaded', workflow);
          this.workflow_data = workflow.data;
          this.cloudDefId = this.workflow_data.metadata.cloudDefinitionId;
          this.dataPath = this.workflow_data.metadata.pipeline_params.data_path;
          this.setKeyParams(this.workflow_data)

          if (
            this.workflow_data &&
            this.workflow_data.tower_reports &&
            this.workflow_data.tower_reports.hasReports
          ) {
            console.log(
              'WorkflowPageComponent: You can haz repoerts!',
              this.workflow_data.tower_reports.reports
            );
            this.reports = this.workflow_data.tower_reports.reports;
            this.get_key_metrics();
          } 
        });
    });
  }

  setKeyParams(data: any): void {
    // Safely access nested properties using optional chaining
    const pipelineParams = data?.metadata?.pipeline_params;

  // Always show the value if it exists with appropriate icons
  if (data?.pipeline_version) {
    this.key_run_params.push({ label: "v" + data.pipeline_version, icon: "pi pi-info" });
  }
  if (pipelineParams?.data_path) {
    this.key_run_params.push({ label: "Data Path: " + pipelineParams.data_path, icon: "pi pi-folder-open" });
  }
  if (pipelineParams?.ref_genome) {
    this.key_run_params.push({ label: pipelineParams.ref_genome, icon: "pi pi-dna" });
  }

  // Check for boolean values in pipelineParams and add icons accordingly
  this.setBooleanParams(pipelineParams);

  }

  private setBooleanParams(pipelineParams: any): void {
    const booleanParams: { [key: string]: string } = {
      'Call Germline Variants': 'call_germline_variants',
      'Call Somatic Variants': 'call_somatic_variants',
      'CHG/CHH Contexts': 'chg_chh_contexts',
      'Compute ASM': 'compute_asm'
    };

    Object.keys(booleanParams).forEach(paramName => {
      const paramKey = booleanParams[paramName];
      if (pipelineParams[paramKey] === true) {
        this.key_run_params.push({ label: paramName, icon: 'pi pi-check', color: 'green' });
      } else if (pipelineParams[paramKey] === false) {
        this.key_run_params.push({ label: paramName, icon: 'pi pi-times', color: 'red' });
      }
    });
  }

  restoreSelectedMetrics(savedMetricNames: string[]): void {
    try {
      if (this.groupedMetrics.length) {
        console.log('Grouped Metrics:', this.groupedMetrics);
        console.log('Restoring selected metrics:', savedMetricNames);
        this.selectedMetrics = [];
        this.groupedMetrics.forEach(group => {
          if (group.items && Array.isArray(group.items)) {
            group.items.forEach((item: any) => {
              if (savedMetricNames.includes(item.label)) {
                console.log('Restoring selected metric:', item.label, item.value.fieldName);
                this.selectedMetrics.push({
                  label: item.label,
                  fieldName: item.value.fieldName,
                  numFormat: item.value.numFormat,
                  data: item.value.data,
                  description: item.value.description,
                  doc_link: item.value.doc_link
                });
              }
            });
          } else {
            console.log('Group items are not an array:', group.items);
          }
        });
  
        console.log('Restored selected metrics:', this.selectedMetrics);
        this.cdr.detectChanges();

      } else {
        console.log('Grouped metrics are not available yet.');
      }
    } catch (error) {
      console.error('Error in restoreSelectedMetrics:', error);
    }
  }

  get_key_metrics(): void {
    console.log('WorkflowPageComponent: Getting key metrics');
    let company = this.altoCompanyService.getCurrentCompany();
    let workspace = this.altoWorkspaceService.getCurrentWorkspace();

    // Get and print the CSV data using the downloadReport service
    this.reportsService.getKeyMetrics(company.id, workspace.id, this.workflowId!).subscribe({
      next: (response: any) => {
        this.metricsResponseData = response.data; 
        this.transformMetricsData();
      },
      error: (error: any) => {
        console.error('WorkflowPageComponent: Error getting Key Metrics Data', error);
        this.failedToGetMetrics = true;
      },
      complete: () => console.log('WorkflowPageComponent: Completed getting Key Metrics Data'),
    });
  }

  onSelectedMetricsChange(): void {
    // Store only the names (labels) of the selected metrics
    const selectedMetricNames = this.selectedMetrics.map(metric => metric.label);
    this.localStorageService.setItem('selectedMetrics', selectedMetricNames);
    console.log('WorkflowPageComponent: Selected metrics changed', this.selectedMetrics);
    this.cdr.detectChanges(); // Trigger change detection

  }
  

  transformMetricsData(): void {
    if (this.metricsResponseData && this.metricsResponseData.sections) {
      this.groupedMetrics = this.metricsResponseData.sections.map((section: any) => ({
        label: section.name,
        doc_link: section.doc_link,
        items: section.fields.map((field: any) => ({
          label: field.friendlyName,
          value: {
            label: field.friendlyName,
            fieldName: field.fieldName,
            numFormat: field.num_format,
            data: field.data,
            description: field.description, // Ensure description is included
            doc_link: section.doc_link
          }
        }))
      }));
    // Load selected metrics names from local storage
    const savedMetricNames = this.localStorageService.getItem<string[]>('selectedMetrics') || [];
    console.log('WorkflowPageComponent: Saved metric names', savedMetricNames);
      this.restoreSelectedMetrics(savedMetricNames);
    // Trigger change detection after updating groupedMetrics and selectedMetrics
    this.cdr.detectChanges();
    }
  }
  


  viewReport(report: any): void {
    this.loadingHTMLReport = true;
    const basePath = this.workflow_data.tower_reports.basePath;
    let company = this.altoCompanyService.getCurrentCompany();
    let workspace = this.altoWorkspaceService.getCurrentWorkspace();
  
    this.reportsService
      .downloadReport(company.id, workspace.id, this.workflowId!, basePath, report)
      .subscribe({
        next: (blob: Blob) => {
          console.log('Report content:', blob);
          const blobUrl = URL.createObjectURL(blob);
  
          // Open the HTML content in a new tab
          window.open(blobUrl, '_blank');         
          this.loadingHTMLReport = false;
          // Revoke the object URL after use (optional but recommended for cleanup)
          URL.revokeObjectURL(blobUrl);
        },
        error: (error: any) => {
          console.error('View report error:', error);
          this.loadingHTMLReport = false;

        },
        complete: () => console.log('View report completed.'),
      });
  }
  

  fileSelected(event: {}) {}

  downloadReport(report: any): void {
    this.loadingHTMLReport = true;
    console.log('WorkflowPageComponent: Downloading report', report);
    // Assuming 'basePath' is stored in 'tower_reports' within 'workflow_data'
    const basePath = this.workflow_data.tower_reports.basePath;
    let company = this.altoCompanyService.getCurrentCompany();
    let workspace = this.altoWorkspaceService.getCurrentWorkspace();
    this.reportsService
      .downloadReport(company.id, workspace.id, this.workflowId!, basePath, report)
      .subscribe({
        next: (blob: Blob) => {
          // Explicitly type the parameter as Blob
          console.log('Downloaded report:', blob);
          const blobUrl = URL.createObjectURL(blob);
          const anchor = document.createElement('a');
          anchor.download = report.display;
          anchor.href = blobUrl;
          anchor.click();
          this.loadingHTMLReport = false;
          URL.revokeObjectURL(blobUrl); // Cleanup the URL object
        },
        error: (error: any) => {
          console.error('Download error:', error);
          this.loadingHTMLReport = false;
        },
        complete: () => console.log('File download completed.'),
      });
  }
  ngOnDestroy(): void {
    // Unsubscribe to prevent memory leaks
    this.routeSub?.unsubscribe();
  }
}
