<div
  class="position-fixed top-0 end-0 bottom-0 start-0 bg-white d-flex justify-content-center align-items-center"
  style="z-index: 1050"
>
  <div class="text-center">
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
    <h2 class="mt-3">biomodal</h2>
    <p>Loading data...</p>
  </div>
</div>
