import { HttpInterceptorFn, HttpRequest } from '@angular/common/http';

export const authInterceptor: HttpInterceptorFn = (req: HttpRequest<any>, next) => {
  // Clone the request to include withCredentials: true
  const modifiedRequest = req.clone({
    withCredentials: true,
  });

  // Directly call next with the modified request
  return next(modifiedRequest);
};
