import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { DataViewModule } from 'primeng/dataview';
import { WorkspaceListComponent } from '@biomodal-webapps/ui-components';
 
@Component({
    selector: 'biomodal-webapps-step-select-workspace',
    standalone: true,
    templateUrl: './step-select-workspace.component.html',
    styleUrl: './step-select-workspace.component.css',
    imports: [CommonModule, DataViewModule, WorkspaceListComponent]
})
export class StepSelectWorkspaceComponent {

  destroy$: Subject<boolean> = new Subject<boolean>();
  public workspaces: { id: number, name: string }[] = [];
  public selectedWorkspace: { id: number, name: string } | null = null;
  public showCreateForm: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    // Subscribe to route data to get the router state
    this.route.paramMap.subscribe(() => {
      const state = window.history.state;
      if (state && state['workspaces']) {
        this.workspaces = state['workspaces'];
        console.log('StepSelectWorkspaceComponent: workspaces', this.workspaces);
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  onWorkspaceSelected(workspace: { id: number, name: string }) {
    this.selectedWorkspace = workspace;
    console.log('Selected workspace:', workspace);
    this.router.navigate(['launch-duet/introduction'], { state: { workspace: workspace }});
  }


}
