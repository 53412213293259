import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { environment } from 'apps/alto/src/environments/environment';

@Component({
  selector: 'biomodal-webapps-landing-page',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './landing-page.component.html',
  styleUrl: './landing-page.component.css',
})
export class LandingPageComponent {
  login() {
    window.location.href = `${environment.apiLoginPage}?redirect_url=${environment.redirect_url}`;
  }

  internal_login() {
    window.location.href = `${environment.apiGoogleLoginPage}?redirect_url=${environment.redirect_url}`;
  }
}
