import { Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, EMPTY, catchError, filter, map, switchMap, take, takeUntil, tap } from 'rxjs';
import {
  AltoCompanyOnboardingService,
  AltoCompanyService,
  AltoPersonOnboardingService,
  AltoPersonService,
  OnboardingStatus,
} from '@biomodal-webapps/alto-service';
import { PeopleService } from '@biomodal-webapps/people';

@Injectable({
  providedIn: 'root',
})
export class AltoOnboardingOrchestrationService {

  // create a boolean behaviour subjct to emite when checking is complete or in progress
  private currentOnboardingStatusSubject = new BehaviorSubject<boolean>(false);
  public  currentOnboardingStatus$ = this.currentOnboardingStatusSubject.asObservable();

  // create a boolean behaviour subject to emit when onboarding is in progress
  private isOnboardingSubject = new BehaviorSubject<boolean>(false);
  public isOnboarding$ = this.isOnboardingSubject.asObservable();

  constructor(
    private router: Router,
    private peopleService: PeopleService,
    private altoCompanyService: AltoCompanyService,
    private altoCompanyOnboardingService: AltoCompanyOnboardingService,
    private altoPersonOnboardingService: AltoPersonOnboardingService,
    private altoPersonService: AltoPersonService
  ) {
    /// login and auth guards check the logged in user
    // against the person service amd will emit the user profile
    // to the currentUser$ observable. We will use this observable
    // to fetch the user's company and person details from Alto
    // and redirect to the onboarding console if needed.
    this.peopleService.currentUser$
      .pipe(
        tap((user) => {
          console.log('AltoOnboardingOrchestrationService: detected new user', user);
          if (user) {
            console.log('AltoOnboardingOrchestrationService: gonna validate alto state');
          }
        }),
        filter((user) => user !== null), // This line prevents null users from proceeding.
        switchMap((person) => {
          /// give this is triggered by a user change, we should always
          // fetch the company and person details from Alto using api
          // rather than relying on the cached data in a behavioursubject.
          return this.fetchAndHandleAltoCompany(person).pipe(
            map((altoCompany) => ({ person, altoCompany }))
          );
        }),
        //Then we check the person is onboarded and if not, onboard the person.
        switchMap(({ person, altoCompany }) =>
          this.fetchAndHandleAltoPerson(person).pipe(
            map((altoPerson) => ({ person, altoCompany, altoPerson }))
          )
        ),
        catchError((error) => {
          // this.router.navigate(['/error'], {
          //   state: { title: 'User Error', message: error.message },
          // });
          return EMPTY;
        })
      )
      .subscribe({
        next: ({ person, altoCompany, altoPerson }) => {
          // this.isLoading = false;
          console.log(
            'All operations completed successfully',
            person,
            altoCompany,
            altoPerson
          );
          this.isOnboardingSubject.next(false);
          this.currentOnboardingStatusSubject.next(true);
        },
        error: (error) => {
          console.error('Stream error', error);
          // Handle final error state
        },
      });
  }

  fetchAndHandleAltoCompany(person: any) {
    // this checks if the company is in *ALTO*, not the CRM.
    // If the company is not found, we onboard the company.
    return this.altoCompanyService.fetchAltoCompany(person.company.crm_id).pipe(
      tap((company) => {
        if (!company){
          console.log('Onboarding Orchestration Service: ERROR Company details not found in alto');
          throw new Error('Company details not found in alto');
        }

        if (company.workspaces.length === 0) {
          console.log('Onboarding Orchestration Service: Company has no workspaces try to onboard again');
          throw new Error('Company has no workspaces');
        }

        console.log(
          'Onboarding Orchestration Service: Company fetched - Onboarding status is confirmed',
          company
        );
      }),
      // Simulate an error by uncommenting the following SWITCHMAP line
      //switchMap(() => throwError(() => new Error('Simulated fetch error'))),
      //switchMap(() => throwError({status: 404, message: 'Simulated 404 error - Company not found'})),
      catchError((error) => {
        console.error('Error Handler: Failed to fetch company details', error);
        // if (error.status === 404 || error.message.includes('Company has no workspaces')) {
        //   //this.showModal = true;
        //   // If the error is a 404, attempt to onboard the company and return the observable from this operation
        //   this.isOnboardingSubject.next(true);
        //   return this.altoCompanyOnboardingService
        //     .onBoardCompany(person.company.crm_id)
        //     .pipe(
        //       // After successful onboarding, fetch the company again to get the newly onboarded company details
        //       switchMap(() =>
        //         this.altoCompanyService.fetchAltoCompany(person.company.crm_id)
        //       )
        //     );
        // } else {
          this.router.navigate(['/error'], {
            state: { title: 'Tenancy Error: Company not onboarded', message: error.message },
          });
          return EMPTY;
        //}
      })
    );
  }

  fetchAndHandleAltoPerson(person: any) {
    // this checks if the company is in ALTO, not the CRM.
    // If the company is not found, we onboard the company.
    return this.altoPersonService
      .fetchAltoPerson(person.company.crm_id, person.crm_id)
      .pipe(
        tap((altoPerson) => {
          if (!altoPerson) throw new Error('Person details not found in alto');
          console.log(
            'HomeComponent: altoPerson fetched - Onboarding status is confirmed',
            altoPerson
          );
        }),
        catchError((error) => {
          // if (error.status === 404) {
          //   this.isOnboardingSubject.next(true);
          //   return this.altoPersonOnboardingService
          //     .onBoardPerson(person.company.crm_id, person.crm_id)
          //     .pipe(
          //       // After successful onboarding, fetch the person again to get the newly onboarded person details
          //       switchMap(() =>
          //         this.altoPersonService.fetchAltoPerson(
          //           person.company.crm_id,
          //           person.crm_id
          //         )
          //       )
          //     );
          // } else {
            this.router.navigate(['/error'], {
              state: { title: 'Profile Error: User not onboarded in this tenancy', message: error.message },
            });
            return EMPTY; // Stop the chain if person fetching fails
          //}
        })
      );
  }

}
