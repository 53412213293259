<!--
  Copyright (C) 2023 Cambridge Epigenetix. All rights reserved.
-->
<biomodal-webapps-image-hero
    [bgImage]="'assets/technology-1.png'"
    [title]="title"
    [description]="message"
    [buttonText]="'Go Back'"
    [action]="goBack"
    [hasWavyBottom]="false"
>
</biomodal-webapps-image-hero>
