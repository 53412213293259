import { Route } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { LandingPageComponent } from './pages/landing/landing-page.component';
import { AuthGuard } from '@biomodal-webapps/auth-services';
import { MyPipelinesPageComponent } from './pages/my-pipelines/my-pipelines-page.component';
import { ForbiddenPageComponent, ErrorPageComponent } from '@biomodal-webapps/ui-components';
import { LaunchPipelinePageComponent } from './pages/launch-pipeline/launch-pipeline-page.component';
import { DuetLaunchIntroductionComponent } from './pages/launch-pipeline/step-introduction/step-introduction.component';
import { ConfigureWorkspaceComponent } from './pages/launch-pipeline/step-configure-workspace/step-configure-workspace.component';
import { DuetLaunchSelectParamsComponent } from './pages/launch-pipeline/step-select-params/step-select-params.component';
import { WorkflowPageComponent } from './pages/workflow/workflow-page.component';
import { StepRecipeSelectionComponent } from './pages/launch-pipeline/step-recipe-selection/step-recipe-selection.component';
import { StepSelectWorkspaceComponent } from './pages/launch-pipeline/step-select-workspace/step-select-workspace.component';
import { TeamPortalComponent } from './pages/team-portal/team-portal.component';
import { StepSelectFilesTreeComponent } from './pages/launch-pipeline/step-select-files-tree/step-select-files-tree.component';

export const appRoutes: Route[] = [
    { path: '', component: LandingPageComponent },
    { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
    { path: 'portal', component: TeamPortalComponent, canActivate: [AuthGuard] },
    { path: 'my-pipelines', component: MyPipelinesPageComponent, canActivate: [AuthGuard] },
    { path: 'launch-duet', component: LaunchPipelinePageComponent, canActivate: [AuthGuard],
         children: [
            { path: 'introduction', component: DuetLaunchIntroductionComponent },
            { path: 'recipes', component: StepRecipeSelectionComponent },
            { path: 'configure-workspace', component: ConfigureWorkspaceComponent },
            // { path: 'select-files', component: DuetLaunchSelectFilesComponent},
            { path: 'select-files', component: StepSelectFilesTreeComponent },
            { path: 'select-params', component: DuetLaunchSelectParamsComponent},
            { path: 'workspace-selection', component: StepSelectWorkspaceComponent}
      ]},
      //workflow path should have a dynamic parameter
    { path: 'workflow/:workflowId', component: WorkflowPageComponent, canActivate: [AuthGuard]},
    { path: 'forbidden', component: ForbiddenPageComponent },
    { path: 'error', component: ErrorPageComponent },
];
