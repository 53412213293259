<!--
  Copyright (C) 2023 Cambridge Epigenetix. All rights reserved.
-->
<div
    class="text-section d-flex align-items-center justify-content-center text-black text-center" style="margin-bottom: 100px;"
>
    <div class="container">
        <div class="content w-100">
            <h1 class="title">{{ title }}</h1>
            <p class="lead">
                {{ body }}
            </p>
            <a class="btn start-button fw-bold" (click)="executeAction()">{{ buttonText }}</a>
        </div>
    </div>
</div>
