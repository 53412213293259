import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { ImageHeroComponent } from '@biomodal-webapps/ui-components';

interface ErrorState {
  title?: string;
  message?: string;
}

@Component({
  selector: 'biomodal-webapps-error-page',
  standalone: true,
  imports: [CommonModule, ImageHeroComponent],
  templateUrl: './error-page.component.html',
  styleUrl: './error-page.component.css',
})
export class ErrorPageComponent {
  title: string = 'Error';
  message: string =
    "We apologize for the inconvenience, but it seems there has been an error, please contact our support team for assistance.";

  constructor(private router: Router) {}

  ngOnInit() {
    console.log('Error Page: ngOnInit');

    const state: ErrorState = history.state;

    console.log('Error Page: ngOnInit: state', state);

    this.title = state?.title || 'Error';
    this.message =
      state?.message ||
      "We apologize for the inconvenience, but it seems there has been an error, please contact our support team for assistance.";
    }

  goBack() {
    window.history.back();
  }
}
