import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Subject, filter, takeUntil, tap } from 'rxjs';
import { AltoCompanyService } from '@biomodal-webapps/alto-service';

@Component({
  selector: 'biomodal-webapps-workspace-list',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './workspace-list.component.html',
  styleUrls: ['./workspace-list.component.css'],
})
export class WorkspaceListComponent implements OnInit, OnDestroy {
  @Input() mode: 'list' | 'dropdown' = 'list'; // Add mode input
  @Output() workspaceSelected = new EventEmitter<{ id: number, name: string }>();

  destroy$: Subject<boolean> = new Subject<boolean>();
  isLoading: boolean = true;
  workspaces: { id: number, name: string }[] = [];
  selectedWorkspace: { id: number, name: string } | null = null;

  constructor(private companyService: AltoCompanyService) {}

  ngOnInit(): void {
    this.companyService.currentCompany$.pipe(
      filter(company => !!company),
      tap(company => {
        this.isLoading = false;
        this.workspaces = company.workspaces;
        console.log('Workspace List: mode:', this.mode, 'workspaces:', this.workspaces);
      }),
      takeUntil(this.destroy$)
    ).subscribe();
  }

  onWorkspaceSelected(workspace: { id: number, name: string }) {
    this.selectedWorkspace = workspace;
    console.log('Selected workspace:', workspace);
    this.workspaceSelected.emit(workspace);
  }

  onDropdownSelected(event: Event) {
    const target = event.target as HTMLSelectElement;
    const workspaceId = target.value;  // Get the selected workspace ID
    if (workspaceId) {
      console.log('Selected workspace ID:', workspaceId);
      const workspace = this.workspaces.find(w => w.id.toString() === workspaceId);
      if (workspace) {
        this.onWorkspaceSelected(workspace);
      } else {
        console.log('No workspace found with id:', workspaceId);
      }
    }
  }
  

  onEdit(workspace: { id: number, name: string }) {
    console.log('Edit workspace:', this.selectedWorkspace);
  }

  onDelete(workspace: { id: number, name: string }) {
    console.log('Delete workspace:', this.selectedWorkspace);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
