/*
The PeopleService emits a new user profile when the user logs in or when the session is validated. This GtmService will listen to these changes and push the relevant IDs to the GTM data layer.

Example Data Push
When a user logs in and their profile is emitted, the GtmService will push the following data to the GTM data layer:

{
  'event': 'userLogin',
  'userId': '003O200000B0FvNIAV',
  'companyId': '001O200000Br4YbIAJ'
}
*/
/// <reference path="../typings.d.ts" />


import { Injectable } from '@angular/core';
import { PeopleService, UserProfile } from '@biomodal-webapps/people';

interface GTMDataLayerEvent {
  event: string;
  [key: string]: any; // Allow any additional properties
}

@Injectable({
  providedIn: 'root'
})
export class GtmService {
  constructor(private peopleService: PeopleService) {
    this.subscribeToUserProfile();
  }

  private subscribeToUserProfile() {
    this.peopleService.currentUser$.subscribe((profile: UserProfile | null) => {
      if (profile) {
        this.trackUserLogin(profile.crm_id, profile.company_id);
      }
    });
  }

  private pushToDataLayer(event: string, data: any) {
    const gtmEvent: GTMDataLayerEvent = { event, ...data }; 
    console.log('GTM Data Layer Push:', gtmEvent);
    
    if (window.location.hostname !== 'localhost') { // Avoid logging data on localhost
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(gtmEvent);
    }
  }

  private trackUserLogin(userId: string, companyId: string) {
    this.pushToDataLayer('userLogin', {
      userId,
      companyId
    });
  }
}
