<h2>Metasheet and Files</h2>


<p style="margin-bottom: 25px">
  <strong>CSV metasheet selection</strong>: Below are your cloud projects, buckets and folder. Please navigate to the metasheet you wish to use for this run. It must be in the
  same folder/bucket as the 'nf-input' folder which contains your fastq files. Please view <a href="https://biomodal.com/kb_articles/Usage/#sequencing-run-metadata-file" target="_blank" style="color: #9cdbd9">this document</a> for more information about the metasheet requirements.
</p>
<div class="row g-3 mb-4">
  <div class="col-12">
    <biomodal-webapps-filebrowser 
      [data]="data"
      [workspace]="workspace!"
      [companyId]="companyId!"
      (selectionChange)="onFileBrowserSelection($event)"
    ></biomodal-webapps-filebrowser>
  </div>
</div>
<hr />
<div *ngIf="cloudDefId" style="margin-bottom: 20px">
  <strong style="color: white">Select Compute Resource</strong>
  <p style="margin-bottom: 25px">
    Select which compute environment you wish to use for this run.
    <span pTooltip="The compute environment is where your analysis will be run. Think of it as the computer doing the work." tooltipPosition="right">
      <i class="pi pi-info-circle"></i>
    </span>
  </p>
  <biomodal-webapps-compute-environments-list [showText]="false"></biomodal-webapps-compute-environments-list>
</div>
<hr />
<div style="text-align: right">
  <button
    class="progress-button"
    [routerLink]="['/launch-duet/select-params']"
    [disabled]="!validSelection()"
    pTooltip="Please select a CSV file and compute resource to proceed."
    tooltipPosition="top"
  >
    Next
  </button>
</div>
<div *ngIf="validationMessages.length > 0">
  <p-messages [(value)]="validationMessages" [enableService]="false"></p-messages>
</div>
<p-messages 
[(value)]="messages" 
[enableService]="false" 
[closable]="false" />
