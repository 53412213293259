import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChecklistComponent, ChecklistItem } from '@biomodal-webapps/ui-components';
import { AltoPersonService, WorkspaceService, AltoCompanyService } from '@biomodal-webapps/alto-service';
import { Subject, takeUntil, tap, filter, switchMap, catchError, of } from 'rxjs';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { PeopleService } from '@biomodal-webapps/people';

@Component({
  selector: 'alto-duet-launch-introduction',
  standalone: true,
  imports: [CommonModule, ChecklistComponent, RouterModule],
  templateUrl: './step-introduction.component.html',
  styleUrl: './step-introduction.component.css',
})
export class DuetLaunchIntroductionComponent {
  destroy$: Subject<boolean> = new Subject<boolean>();
  isWorkspaceReady: boolean = false;
  isLoading: boolean = true;
  errorMessage: { error: string; message: string; success: boolean } | null = null;

  checklistItems: ChecklistItem[] = [
    {
      id: 'item1',
      label: 'Add a metadata CSV',
      description:
        'Place your metadata CSV file directly into your chosen cloud bucket or a specific subfolder.',
      checked: false,
    },
    {
      id: 'item2',
      label: 'Create input folder',
      description:
        'Ensure a folder named "nf-input", containing your FASTQs, is present in the same location as your metadata CSV file.',
      checked: false,
    },
  ];

  constructor(
    private personService: PeopleService,
    private workspaceService: WorkspaceService,
    private altoCompanyService: AltoCompanyService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    // Subscribe to route data to get the router state
    this.route.paramMap.subscribe(() => {
      const state = window.history.state;
      console.log('DuetLaunchIntroductionComponent: Router state', state);
  
      if (state && state['workspace']) {
        const company_id = this.altoCompanyService.getCurrentCompany().id;
        
        this.workspaceService.fetchCompanyWorkspace(company_id, state['workspace']['id']).pipe(
          tap((workspace) => {
            console.log('LaunchPipelinePageComponent: detected workspace change', workspace);
            this.initializeWorkspace(workspace);
            console.log(`LaunchPipelinePageComponent: Workspace is ${this.isWorkspaceReady ? 'ready' : 'not ready'}`);
          }),
          catchError((error) => {
            console.error('LaunchPipelinePageComponent: failed to fetch company workspace', error);
          this.errorMessage = {
              error: error.error.error,
              message: error.error.message,
              success: error.error.success,
            };
            return of(null); // Return null to terminate the observable in case of error
          })
        ).subscribe();
      }
    });
  }

  private initializeWorkspace(workspace: any) {
    console.log('Initializing workspace', workspace);
    // Check if the workspace is ready
    const hasCloudDefinitions = workspace.cloud_definitions.length > 0;
    const hasComputeEnvironments = workspace.cloud_definitions.some(
      (def: any) => def.compute_environments.length > 0
    );

    this.isWorkspaceReady = hasCloudDefinitions && hasComputeEnvironments;
    this.isLoading = false; // Hide the spinner
    console.log(`LaunchPipelinePageComponent: Workspace is ${this.isWorkspaceReady ? 'ready' : 'not ready'}`);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  handleItemChange(updatedItems: ChecklistItem[]) {
    this.checklistItems = updatedItems;
  }

  get allItemsChecked(): boolean {
    return this.checklistItems.every((item) => item.checked);
  }

  get loggedInUserName(): string {
    return this.personService.getCurrentUser()?.first_name || '';
  }
}
