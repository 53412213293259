import { ChangeDetectorRef, Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { CommonModule, NgIf } from '@angular/common';
import { FileBlob, CloudBucket, CloudFolder, CredentialsService, CloudDefinition } from '@biomodal-webapps/alto-service';
import { FormsModule } from '@angular/forms';
import { TableModule } from 'primeng/table';
import { TreeTableModule } from 'primeng/treetable';
import { TreeNode } from 'primeng/api';
import { forkJoin } from 'rxjs';
import { FileDownloadButtonComponent } from './file-download-button/file-download-button.component';
import { DownloadSnippetModalComponent } from './download-snippet-modal/download-snippet-modal.component';

@Component({
  selector: 'biomodal-webapps-filebrowser',
  standalone: true,
  imports: [CommonModule, FormsModule, TreeTableModule, TableModule, FileDownloadButtonComponent, NgIf, DownloadSnippetModalComponent],
  templateUrl: './filebrowser.component.html',
  styleUrl: './filebrowser.component.css',
})
export class FilebrowserComponent {
  /* Tightly follows which file, folder, cloud projetc or bucket is selected. Updates these selects
  even when a sub type is selected. i.e. you select a file, and this will update the current folder, 
  project and bucket. 
  
  Outside users can subscribe to the selected file, folder, project or bucket and respond to changes.
  */

  @Input() data: TreeNode[] = [];
  @Input() companyId: string = '';
  @Input() workspace: any;
  @Input() allowDownload: boolean = true;

  // state management
  selectedProject: CloudDefinition | null = null;
  selectedBucket: string = '';
  selectedFolder: string = '';
  selectedFile: string = '';

  //emit the above variables as they change
  @Output() selectionChange = new EventEmitter<{
    project: CloudDefinition | null;
    bucket: string;
    folder: string;
    file: string;
  }>();

  constructor(private credentialsService: CredentialsService, private cdr: ChangeDetectorRef) {}

  onNodeSelect(event: any) {
    console.log('onNodeSelect', event);
    const node = event.node;
    if (node) {
      this.setSelections(node);
      this.logSelections();
      this.selectionChange.emit({
        project: this.selectedProject,
        bucket: this.selectedBucket,
        folder: this.selectedFolder,
        file: this.selectedFile,
      });
    }
  }

  onNodeExpand(event: any) {
    console.log('onNodeExpand', event);
    const node = event.node;
    if (node) {
      this.setSelections(node);
      this.logSelections();

      if (node.data.type === 'Cloud Project') {
        this.handleCloudExpand(node);
      } else if (node.data.type === 'Bucket') {
        this.handleBucketExpand(node);
      } else if (node.data.type === 'folder') {
        this.handleFolderExpand(node);
      }
    }
  }

  onNodeUnselect(event: any) {
    console.log('onNodeUnselect', event);
    this.selectedProject = null;
    this.selectedBucket = '';
    this.selectedFolder = '';
    this.selectedFile = '';
  }

  logSelections() {
    console.log('selectedProject', this.selectedProject);
    console.log('selectedBucket', this.selectedBucket);
    console.log('selectedFolder', this.selectedFolder);
    console.log('selectedFile', this.selectedFile);
  }

  setSelections(node: TreeNode) {
    // Check the type of the node and update selections accordingly
    if (node.data.type === 'Cloud Project') {
      this.selectedProject = this.getCloudDefinition(node);
      this.selectedBucket = '';
      this.selectedFolder = '';
      this.selectedFile = '';
    } else if (node.data.type === 'Bucket') {
      this.selectedBucket = node.data.name;
      // Ensure the project is set based on the parent node (Cloud Project)
      const parentProjectNode = this.findParentNodeOfType(node, 'Cloud Project');
      if (parentProjectNode) {
        this.selectedProject = this.getCloudDefinition(parentProjectNode);
      }
      this.selectedFolder = '';
      this.selectedFile = '';
    } else if (node.data.type === 'folder') {
      // Update `selectedFolder` with the full folder path
      this.selectedFolder = this.getFullFolderPath(node);

      // Ensure the bucket and project are set based on the parent nodes
      const parentBucketNode = this.findParentNodeOfType(node, 'Bucket');
      if (parentBucketNode) {
        this.selectedBucket = parentBucketNode.data.name;
      }
      const parentProjectNode = this.findParentNodeOfType(node, 'Cloud Project');
      if (parentProjectNode) {
        this.selectedProject = this.getCloudDefinition(parentProjectNode);
      }
      this.selectedFile = '';
    } else if (node.data.type === 'file') {
      this.selectedFile = node.data.name;
      // Ensure the folder, bucket, and project are set based on the parent nodes
      const parentFolderNode = this.findParentNodeOfType(node, 'folder');
      if (parentFolderNode) {
        this.selectedFolder = this.getFullFolderPath(parentFolderNode);
      }
      const parentBucketNode = this.findParentNodeOfType(node, 'Bucket');
      if (parentBucketNode) {
        this.selectedBucket = parentBucketNode.data.name;
      }
      const parentProjectNode = this.findParentNodeOfType(node, 'Cloud Project');
      if (parentProjectNode) {
        this.selectedProject = this.getCloudDefinition(parentProjectNode);
      }
    }

    this.credentialsService.selectCredentials(this.selectedProject!)
  }

  private findParentNodeOfType(node: TreeNode, type: string): TreeNode | null {
    let currentNode = node;
    while (currentNode.parent && currentNode.data.type !== type) {
      currentNode = currentNode.parent;
    }
    return currentNode.data.type === type ? currentNode : null;
  }

  handleCloudExpand(node: TreeNode) {
    const cloudDef = this.getCloudDefinition(node);
    if (!cloudDef) return;
    this.credentialsService.get_cloud_buckets(this.companyId, this.workspace.id, cloudDef.id).subscribe({
      next: (buckets: CloudBucket[]) => {
        if (buckets.length === 0) {
          // If no buckets are found, display a message node
          this.handleError(node, 'Error loading buckets');
        } else {
          const bucketNodes = this.mapBucketsToTreeNodes(buckets);
          this.updateNodeWithChildren(node, bucketNodes);
        }
      },
      error: () => this.handleError(node, 'Error loading buckets'),
    });
  }

  handleBucketExpand(node: TreeNode) {
    const folderPath = ''; // Root level path
    this.fetchAndUpdateNode(node, folderPath);
  }
  

  handleFolderExpand(node: TreeNode) {
    const folderPath = this.getFullFolderPath(node); // Get the full path of the current folder
    this.fetchAndUpdateNode(node, folderPath);
  }
  

  private fetchAndUpdateNode(node: TreeNode, folderPath: string) {
    // Observable to get folders/subfolders
    const foldersObservable = this.credentialsService.get_cloud_folders(
      this.companyId,
      this.workspace.id,
      this.selectedProject!.id,
      this.selectedBucket,
      folderPath
    );
  
    // Observable to get files
    const filesObservable = this.credentialsService.get_files_in_folder(
      this.companyId,
      this.workspace.id,
      this.selectedProject!.id,
      this.selectedBucket,
      folderPath
    );
  
    // Wait for both folders and files to be loaded
    forkJoin([foldersObservable, filesObservable]).subscribe({
      next: ([loadedFolders, loadedFiles]) => {
        console.log('loadedFolders', loadedFolders);
        console.log('loadedFiles', loadedFiles);
  
        // Map and sort folders alphabetically
        const folders = this.mapFoldersToTreeNodes(loadedFolders).sort((a, b) =>
          a.data.name.localeCompare(b.data.name)
        );
  
        // Map and sort files alphabetically
        const files = loadedFiles
          .map((file) => ({
            data: {
              name: file.name,
              type: 'file',
            },
          }))
          .sort((a, b) => a.data.name.localeCompare(b.data.name));
  
        // Combine both folders and files
        const children = [...folders, ...files];
  
        // Update the node with the combined children
        this.updateNodeWithChildren(node, children);
      },
      error: (err) => {
        console.error('Error loading folders or files:', err);
        this.handleError(node, 'Error loading folders or files');
      },
    });
  }
  


  getFullFolderPath(node: TreeNode): string {
    let path = node.data.name;
    let current = node.parent;
    while (current && current.data.type !== 'Bucket') {
      path = current.data.name + path;
      current = current.parent;
    }
    return path;
  }

  private getCloudDefinition(node: TreeNode): any {
    const cloudDef = this.workspace.cloud_definitions.find((def: any) => def.name === node.data.name);
    if (!cloudDef) {
      console.error('Could not find cloud definition for node', node);
      const errorNode = [
        {
          data: {
            name: 'Could not find cloud project in workspace',
            type: 'Error',
          },
        },
      ];
      this.credentialsService.selectCredentials(cloudDef)
      this.updateNodeWithChildren(node, errorNode);
    }
    return cloudDef;
  }

  private mapBucketsToTreeNodes(buckets: CloudBucket[]): TreeNode[] {
    return buckets.map((bucket: CloudBucket) => ({
      data: {
        name: bucket.name,
        location: bucket.region,
        type: 'Bucket',
      },
      children: [
        {
          data: {
            name: 'Loading...',
            type: 'loading',
          },
        },
      ],
    }));
  }

  private mapFoldersToTreeNodes(folders: CloudFolder[]): TreeNode[] {
    return folders.map((folder: CloudFolder) => ({
      data: {
        name: folder.name,
        type: 'folder',
      },
      children: [
        {
          data: {
            name: 'Loading...',
            type: 'loading',
          },
        },
      ],
    }));
  }

  private handleError(node: TreeNode, message: string): void {
    const errorNode = [
      {
        data: {
          name: message,
          type: 'Error',
        },
      },
    ];
    this.updateNodeWithChildren(node, errorNode);
  }

  private updateNodeWithChildren(node: TreeNode, children: TreeNode[]): void {
    node.children = children;
    node.expanded = true;

    // Force the TreeTable to update
    const tempData = [...this.data];
    this.data = [];
    this.cdr.detectChanges();
    this.data = tempData;
  }
}
