import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, delay, map, switchMap, tap } from 'rxjs/operators';
import { ENV_CONFIG } from '@biomodal-webapps/config';

export interface OnboardingStatus {
  step: string;
  status: 'running' | 'complete' | 'failed';
  message?: string;
  error?: any;
}

@Injectable({
  providedIn: 'root',
})
export class AltoCompanyOnboardingService {
  private onboardingStatusSubject = new BehaviorSubject<OnboardingStatus | null>(null);
  public onboardingStatus$ = this.onboardingStatusSubject.asObservable();

  private onboardingCompleteSubject = new BehaviorSubject<boolean>(false);
  public onboardingComplete$ = this.onboardingCompleteSubject.asObservable();

  private apiUrl: string;

  constructor(
    @Inject(ENV_CONFIG) private config: { apiUrl: string },
    private http: HttpClient
  ) {
    this.apiUrl = config.apiUrl;
  }

  onBoardCompany(company_crm_id: string) {
    console.log('Starting onboarding process...');
    this.onboardingCompleteSubject.next(false)
    return this.createAltoCompany(company_crm_id)
      .pipe(
        switchMap(() => this.simulateStep('authorisingAvailablePipelines', 2500, 'Authorising available pipelines...')),
        switchMap(() => this.createWorkspace(company_crm_id)),
        switchMap(() => this.simulateStep('connectingToPipelineRunner', 2000, 'Connecting to pipeline runner...')),
        switchMap(() => this.simulateStep('configuringDuetPipeline', 2500, 'Configuring duet pipeline...')),
        switchMap(() => this.simulateStep('authorisingWorkspace', 2000, 'Authorising workspace...')),
        tap(() => this.onboardingCompleteSubject.next(true)), // Mark onboarding as complete
        catchError(error => {
          console.error('Company Onboarding process failed:', error);
          this.onboardingCompleteSubject.next(false); // Mark onboarding as incomplete/failed
          return throwError(() => error);
        })
      )
  }

  private emitStatus(status: OnboardingStatus) {
    this.onboardingStatusSubject.next(status);
  }

  private createAltoCompany(company_crm_id: string): Observable<any> {
    this.emitStatus({ step: 'createCompany', status: 'running', message: 'Creating company...' });
    let payload = { "company_crm_id": company_crm_id };

    return this.http.post<{ success: boolean; message: string; data: any }>(
      `${this.apiUrl}/alto/companies`, payload).pipe(
        map(response => {
          console.log('Company created: EMITTING COMPLETE STEP');
          this.emitStatus({ step: 'createCompany', status: 'complete', message: 'Company created.' });
          return response.data;
        }),
        catchError(error => {
          this.emitStatus({ step: 'createCompany', status: 'failed', message: 'Failed to create company.', error });
          return throwError(() => error);
        })
      );
  }

// Placeholder method for creating a workspace
private createWorkspace(company_crm_id: string): Observable<any> {
  this.emitStatus({ step: 'createWorkspace', status: 'running', message: 'Creating workspace...' });
  let payload = { "workspace_name": "primary_workspace" };

  return this.http.post<{ success: boolean; message: string; data: any }>(
    `${this.apiUrl}/alto/companies/${company_crm_id}/workspaces`, payload).pipe(
      map(response => {
        console.log('Workspace created: EMITTING COMPLETE STEP');
        this.emitStatus({ step: 'createWorkspace', status: 'complete', message: 'Workspace created.' });
        return response.data;
      }),
      catchError(error => {
        this.emitStatus({ step: 'createWorkspace', status: 'failed', message: 'Failed to create workspace.', error });
        return throwError(() => error);
      })
    );
}

// Utility method for simulating steps with a delay
private simulateStep(step: string, delayMs: number, message: string): Observable<any> {
  this.emitStatus({ step, status: 'running', message });
  return of(null).pipe(
    delay(delayMs),
    map(() => {
      this.emitStatus({ step, status: 'complete', message: `${message} completed.` });
    }),
    catchError(error => {
      this.emitStatus({ step, status: 'failed', message: `${message} failed.`, error });
      return throwError(() => error);
    })
  );
}

}
