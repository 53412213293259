// accordion-wrapper.component.ts
import { CommonModule, NgFor, NgIf, NgSwitchCase } from '@angular/common';
import { FieldWrapper, FieldType, FormlyModule } from '@ngx-formly/core';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription, of, take } from 'rxjs';
import { ReactiveFormsModule, FormControl } from '@angular/forms';

@Component({
  selector: 'formly-accordion-wrapper',
  template: `
    <div class="accordion-item">
      <h2 [attr.id]="headingId" class="accordion-header">
        <button
          class="accordion-button"
          type="button"
          data-bs-toggle="collapse"
          [attr.data-bs-target]="'#' + collapseId"
          [attr.aria-expanded]="false"
          [attr.aria-controls]="collapseId"
        >
          {{ to.label }}
        </button>
      </h2>
      <div
        [id]="collapseId"
        class="accordion-collapse collapse"
        [attr.aria-labelledby]="headingId"
        data-bs-parent="#accordionExample"
      >
        <div class="accordion-body">
          <ng-container #fieldComponent></ng-container>
        </div>
      </div>
    </div>
  `,
  standalone: true,
  imports: [CommonModule],
})
export class AccordionWrapperComponent extends FieldWrapper implements OnInit {
  headingId!: string;
  collapseId!: string;

  ngOnInit() {
    // Use field.key to generate unique IDs for accordion
    this.headingId = `heading-${this.field.key}`;
    this.collapseId = `collapse-${this.field.key}`;
  }
}

@Component({
  selector: 'formly-accordion-section',
  template: `
    <div class="accordion" id="accordionExample">
      <div class="accordion-item">
        <h2 class="accordion-header">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseOne"
            aria-expanded="false"
            aria-controls="collapseOne"
          >
            Advanced Options
          </button>
        </h2>
        <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <ng-container #fieldComponent></ng-container>
          </div>
        </div>
      </div>
    </div>
  `,
  standalone: true,
  imports: [CommonModule],
})
export class AccordionSectionComponent extends FieldWrapper {}

@Component({
  selector: 'formly-headered-section',
  template: `
    <div class="formly-header">
      <h3 [id]="'heading' + field.key">{{ to.label }}</h3>
      <hr />
      <div>
        <ng-container #fieldComponent></ng-container>
      </div>
    </div>
  `,
  standalone: true,
  imports: [FormlyModule, CommonModule],
})
export class FormlyHeaderedSectionWrapperComponent extends FieldWrapper {}

@Component({
  selector: 'formly-field-custom-template',
  standalone: true,
  imports: [NgIf], // Ensure CommonModule is imported for *ngIf
  template: `<div *ngIf="props['template']" [innerHTML]="props['template']"></div>`, // Updated to use innerHTML
})
export class FormlyFieldCustomTemplateComponent extends FieldType implements OnInit, OnDestroy {
  private subscription?: Subscription; // To keep track of the subscription

  ngOnInit() {
    if (this.props['updateTemplate']) {
      this.subscription = this.props['updateTemplate'].subscribe((template: string) => {
        this.props['template'] = template;
      });
    }
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe(); // Ensure we unsubscribe when the component is destroyed
  }
}

@Component({
  selector: 'formly-field-toggle-switch',
  standalone: true,
  imports: [ReactiveFormsModule, FormlyModule],
  template: `
    <div class="form-check form-switch d-flex justify-content-between align-items-center">
      <label class="form-check-label">{{ to.label }}</label>
      <input type="checkbox" class="form-check-input" [formControl]="fc" [formlyAttributes]="field" />
    </div>
  `,
})
export class ToggleSwitchTypeComponent extends FieldType {
  get fc(): FormControl {
    return this.formControl as FormControl; // Type assertion for the FormControl
  }
}

@Component({
  selector: 'formly-field-tile-selection',
  standalone: true,
  imports: [ReactiveFormsModule, FormlyModule, CommonModule, NgFor, NgSwitchCase],
  template: `
    <div class="d-flex flex-wrap">
      <div
        *ngFor="let option of options$ | async"
        class="duet-tile"
        [class.selected]="option.value === formControl.value.pipelineId"
        (click)="selectOption(option.value)"
        style="width: 150px; height: 150px; margin: 5px; display: flex; justify-content: center; align-items: center; cursor: pointer;"
      >
        <ng-container [ngSwitch]="option.type">
          <ng-container *ngSwitchCase="'DUET_MODC'">
            <div class="duet-tile-left">
              <ng-container>
                <img src="../assets/duetmodcicon.svg" class="icon" />
                <img src="../assets/duetmodclogo.svg" class="logo" />
                <div>
                  <span class="badge bg-success" style="position: absolute; right: 10px; bottom: 40px;">
                    Latest
                  </span>
                  <select
                    class="form-select"
                    style="position: absolute; right: 10px; bottom: 10px;"
                    (change)="onVersionChange($event)"
                    (click)="$event.stopPropagation()"
                  >
                    <option *ngFor="let version of option.versions" [value]="version.id">
                      {{ version.label }}
                    </option>
                  </select>
                </div>
              </ng-container>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'DUET_EVOC'">
            <div class="duet-tile-right">
              <ng-container>
                <img src="../assets/duetevocicon.svg" class="icon" />
                <img src="../assets/duetevoclogo.svg" class="logo" />
                <div>
                  <span class="badge bg-success" style="position: absolute; left: 10px; bottom: 40px;">
                    Latest
                  </span>
                  <select
                    class="form-select"
                    style="position: absolute; left: 10px; bottom: 10px;"
                    (change)="onVersionChange($event)"
                    (click)="$event.stopPropagation()"
                  >
                    <option *ngFor="let version of option.versions" [value]="version.id">
                      {{ version.label }}
                    </option>
                  </select>
                </div>
              </ng-container>
            </div>
          </ng-container>
          <ng-container *ngSwitchDefault>
            {{ option.label }}
          </ng-container>
        </ng-container>
      </div>
    </div>
  `,
  styles: [
    `
      .duet-tile {
        display: flex;
        width: 150px;
        height: 150px;
        margin: 5px;
        border-radius: 10px;
        background-color: white;
        border: 1px solid #e0e0e0;
        position: relative;
        overflow: hidden;
      }

      .duet-tile:hover {
        background-color: #d6d6d6;
      }

      .duet-tile img.icon {
        height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
      }

      .duet-tile img.logo {
        width: 40%;
        position: absolute;
        top: 10px;
      }

      .duet-tile .form-select {
        width: 70px;
        height: 25px;
        padding: 0 5px;
        font-size: 12px;
        border-radius: 5px;
        background-color: white;
        border: 1px solid #ccc;
      }

      .duet-tile-left img.icon {
        left: -16px;
      }

      .duet-tile-right img.icon {
        right: -16px;
      }

      .duet-tile-left img.logo {
        right: 10px;
      }

      .duet-tile-right img.logo {
        left: 10px;
      }

      .selected {
        border: 2px var(--warning) solid;
      }
    `,
  ],
})
export class TileSelectionTypeComponent extends FieldType implements OnInit {
  options$: Observable<any[]> = of([]);
  current_version_id: number | undefined = undefined;

  ngOnInit() {
    const options = this.props.options ?? [];
    this.options$ = of(Array.isArray(options) ? options : []);

    // Select the id of the first pipeline and first version by default
    this.options$.pipe(take(1)).subscribe((opts) => {
      if (opts.length > 0) {
        const firstOption = opts[0];
        this.selectOption(firstOption.value);
      }
    });
  }

  selectOption(pipelineId: any) {
    this.options$.pipe(take(1)).subscribe((options) => {
      const pipeline = options.find((p) => p.value === pipelineId);
      if (pipeline) {
        const versionId = pipeline.versions[0]?.id;
        this.formControl.setValue({
          pipelineId: pipeline.value,
          versionId: versionId,
        });
        console.log(this.formControl.value);
      }
    });
  }

  onVersionChange(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    const versionId = parseInt(selectElement.value, 10);
    const currentValue = { ...this.formControl.value, versionId };
    this.formControl.setValue(currentValue);
    console.log(this.formControl.value);
  }
}

@Component({
  selector: 'formly-field-reference-genome',
  standalone: true,
  imports: [ReactiveFormsModule, FormlyModule, CommonModule, NgFor, NgSwitchCase],
  template: `
    <div class="d-flex justify-content-left flex-wrap">
      <div
        *ngFor="let option of options$ | async"
        class="genome-tile"
        [class.selected]="option.value === formControl.value"
        (click)="selectOption(option.value)"
      >
        <div class="icon-wrapper">
          <ng-container [ngSwitch]="option.value">
            <img *ngSwitchCase="'GRCh38Decoy'" src="../assets/human.svg" class="icon" />
            <img *ngSwitchCase="'GRCm38'" src="../assets/mouse-38.svg" class="icon" />
            <img *ngSwitchCase="'GRCm39'" src="../assets/mouse39.svg" class="icon" />
            <img *ngSwitchCase="'mRatBN7'" src="../assets/rat.svg" class="icon" />
            <!-- Add more cases as needed -->
            <img *ngSwitchDefault src="../assets/human.svg" class="icon" />
          </ng-container>
        </div>
        <div class="label">{{ option.label }}</div>
      </div>
    </div>
  `,
  styles: [
    `
      .genome-tile {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 80px;
        margin: 10px;
        cursor: pointer;
        text-align: center;
      }

      .icon-wrapper {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: background-color 0.3s, border 0.3s;
      }

      .icon-wrapper:hover {
        background-color: #f0f0f0;
      }

      .genome-tile.selected .icon-wrapper {
        border: 2px solid var(--warning);
      }

      .icon {
        width: 40px;
        height: 40px;
      }

      .label {
        margin-top: 5px;
        font-size: 10px;
        color: white;
      }
    `,
  ],
})
export class FormlyFieldReferenceGenome extends FieldType implements OnInit {
  options$: Observable<any[]> = of([]);

  ngOnInit() {
    const options = this.props.options ?? [];
    this.options$ = of(Array.isArray(options) ? options : []);
  }

  selectOption(value: any) {
    this.formControl.setValue(value);
  }
}
