import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { LINKS } from '@biomodal-webapps/assets/links';

@Component({
  selector: 'biomodal-webapps-footer',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.css',
})
export class FooterComponent {
  // Declare LINKS as Record<string, any> to avoid type issues
  LINKS: Record<string, any> = LINKS;
  constructor(private router: Router) {}

  get isAtRoot(): boolean {
    //return this.router.url === '/';
    return false
  }

    // Utility function to get object keys for use in *ngFor
    objectKeys(obj: any): string[] {
      return Object.keys(obj);
    }

}
