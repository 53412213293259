/*
  Copyright (C) 2024 Cambridge Epigenetix. All rights reserved.
*/
/// NEXTFLOW CONFIG VALUES
// These values should exactly match the case and spelling of the
// values in the nextflow config file
export const NF_RUN_NAME = 'run_name';
export const NF_TAG = 'tag';
export const NF_META_FILE = 'meta_file';
export const NF_REFERENCE_PATH = 'reference_path'
export const NF_REFERENCE_GENOME = 'ref_genome';
export const NF_DATA_PATH = 'data_path';

export const NF_CGH_CHH_CONTEXTS = 'chg_chh_contexts';
export const NF_CALL_SOMATIC_VARIANTS = 'call_somatic_variants';
export const NF_CALL_GERMLINE_VARIANTS = 'call_germline_variants';
export const NF_USE_GVCF = 'use_gvcf';
export const NF_COMPUTE_ASM = 'compute_asm';
export const NF_TARGETED = 'targeted';
export const NF_TARGET_PANEL = 'target_panel';
export const NF_TARGET_PANEL_OPTIONS = [
  'none',
  'twist_cancer',
  'twist_methylome',
  'twist_exome',
  'horvath',
  'horvath_v2',
  'nonacus',
  ];
  export const NF_TARGET_PANEL_DEFAULT = 'none';
  export const NF_UMI = 'umi';
  
  export const NF_RESOURCE_PROFILE_SELECTION_OPTIONS = [
    {value: 49000000, display: '< 50m'},
    {value: 499000000, display: '50m - 500m'},
    {value: 1000000000, display: '500m - 2b'},
    {value: 3000000000, display: '> 2b'},
    ];
    
    export const NF_SUBSAMPLE = 'subsample';
    export const NF_LIB_PREFIX = 'lib_prefix';
    export const NF_RUN_INTERNAL = 'run_internal_analysis';
    
    export const REFERENCE_GENOMES = [
      { value: 'GRCh38Decoy', display: 'Human GRCh38Decoy' },
      { value: 'GRCm38', display: 'Mouse GRCm38' },
      { value: 'GRCm39', display: 'Mouse GRCm39' },
      { value: 'mRatBN7', display: 'Rat mRatBN7' },
      ];
      
      //defaults
      export const DEFAULT_REFERENCE_PATH = 'gs://nextflow-reference-eu';
      export const NF_PIPELINE_REPO = 'https://github.com/cegx-ds/singleshotseq-internal';
      // export const NF_STATIC_REGISTRY = 'europe-docker.pkg.dev/cegx-nextflow/eu-prod';
      // export const NF_PIPELINE_VERSIONS = ['1.1.2', '1.1.1', '1.1.0'];
      
      
      
      
      /// random run names ;) 
      
      export const adjectives = ['Harmonious', 'Resonant', 'Dynamic', 'Vibrant', 'Majestic', 'Melodic', 'Rhythmic', 'Serene', 'Fortissimo', 'Pianissimo'];
      export const terms = ['Allegro', 'Adagio', 'Crescendo', 'Diminuendo', 'Forte', 'Piano', 'Legato', 'Staccato', 'Sonata', 'Concerto'];
      
      