import { TreeNode } from "primeng/api";

export const data: TreeNode[] = [
    {
      data: { name: 'Prj-Biomodal-Commerical', type: 'Cloud Project' },
      children: [
        {
          data: { name: 'Bucket 1', type: 'Bucket', location: 'us-east-1' },
          children: [
            {
              data: { name: 'folder 1', type: 'folder' },
              children: [
                { data: { name: 'metasheet.csv', type: 'file' } },
                { data: { name: 'some_file.xlsx', type: 'file' } },
                {
                  data: { name: 'nf-input', type: 'folder' },
                  children: [
                    { data: { name: 'myfastq1.fastq', type: 'file' } },
                    { data: { name: 'myfastq2.fastq', type: 'file' } },
                  ],
                },
              ],
            },
            {
              data: { name: 'folder 2', type: 'folder' },
              children: [
                { data: { name: 'metasheet.csv', type: 'file' } },
                { data: { name: 'some_file.xlsx', type: 'file' } },
                {
                  data: { name: 'nf-input', type: 'folder' },
                  children: [
                    { data: { name: 'myfastq1.fastq', type: 'file' } },
                    { data: { name: 'myfastq2.fastq', type: 'file' } },
                  ],
                },
              ],
            },
            {
              data: { name: 'folder 3', type: 'folder' },
              children: [
                { data: { name: 'metasheet.csv', type: 'file' } },
                { data: { name: 'some_file.xlsx', type: 'file' } },
                {
                  data: { name: 'nf-input', type: 'folder' },
                  children: [
                    { data: { name: 'myfastq1.fastq', type: 'file' } },
                    { data: { name: 'myfastq2.fastq', type: 'file' } },
                  ],
                },
              ],
            },
          ],
        },
        {
          data: { name: 'Bucket 2', type: 'Bucket', location: 'us-west-2' },
          children: [{ data: { name: 'Computes.csv', type: 'file' } }],
        },
      ],
    },
  ];

export const cols = [
    { field: 'name', header: 'Name' },
    { field: 'type', header: 'Type' },
    { field: 'location', header: 'Location' }
  ];
