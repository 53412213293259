import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { ImageHeroComponent } from '@biomodal-webapps/ui-components';

interface ForbiddenState {
  title?: string;
  message?: string;
}

@Component({
  selector: 'biomodal-webapps-forbidden-page',
  standalone: true,
  imports: [CommonModule, ImageHeroComponent],
  templateUrl: './forbidden-page.component.html',
  styleUrl: './forbidden-page.component.css',
})
export class ForbiddenPageComponent {
  title: string = 'Access Denied';
  message: string =
    "We apologize for the inconvenience, but it seems you don't have the necessary permissions to access this page. If you believe this is an error, please contact our support team for assistance.";

  constructor(private router: Router) {}

  ngOnInit() {
    console.log('Forbidden Page: ngOnInit');

    const state: ForbiddenState = history.state;

    console.log('Forbidden Page: ngOnInit: state', state);

    this.title = state?.title || 'Access Denied';
    this.message =
      state?.message ||
      "We apologize for the inconvenience, but it seems you don't have the necessary permissions to access this page. If you believe this is an error, please contact our support team for assistance.";
  }

  goBack() {
    window.history.back();
  }
}
