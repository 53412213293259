<!--
  Copyright (C) 2023 Cambridge Epigenetix. All rights reserved.
-->
<form [formGroup]="computeForm" (ngSubmit)="createEnvironment()">
  <div class="form-group">
    <label>Compute Name</label>
    <input
      type="text"
      formControlName="computeName"
      class="form-control"
      placeholder="Enter a custom name"
      title="Any name you want to give to this compute config. You can have lots of these."
    />
  </div>
  <div class="form-group">
    <label>Region</label>
    <select formControlName="region" class="form-select" (change)="onRegionChange($event)">
      <option *ngFor="let region of regions" [value]="region.id">
        {{ region.name }}
      </option>
    </select>
  </div>

  <!-- #select  bucket -->
  <div class="form-group">
    <label>Your bucket</label>
    <input
      type="text"
      list="bucket-suggestions"
      class="form-control"
      formControlName="workDir"
      placeholder="The bucket you created in the checklist at the beginning, e.g., gs://my-root-bucket"
    />
    <datalist id="bucket-suggestions">
      <option *ngFor="let bucket of bucketSuggestions" [value]="bucket"></option>
    </datalist>
    <div *ngIf="computeForm.get('workDir')?.hasError('invalidName')">Invalid bucket name.</div>
    <div *ngIf="bucketSuggestions.length === 0" class="alert alert-warning" role="alert">
      Unable to access cloud project. Please contact support.
    </div>
  </div>

  <div class="form-group">
    <label>Optimize for</label><br />
    <div class="custom-toggle">
      <label class="toggle-btn" [class.active]="isCostEffective" (click)="setOptimization(true)">
        <input type="radio" name="options" id="option1" autocomplete="off" [checked]="isCostEffective" />
        Cost
      </label>
      <label class="toggle-btn" [class.active]="!isCostEffective" (click)="setOptimization(false)">
        <input type="radio" name="options" id="option2" autocomplete="off" [checked]="!isCostEffective" />
        Performance
      </label>
    </div>
  </div>
  <!-- Submit Button and Loading Indicator -->
  <div class="text-right" style="margin-bottom: 20px">
    <button
      *ngIf="!isLoading"
      type="submit"
      class="btn btn-primary"
      [disabled]="!allRequiredToCreateChecked()"
    >
      Create
    </button>
    <div *ngIf="isLoading" class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>

  <!-- Success and Error Messages -->
  <div *ngIf="successMessage" class="alert alert-success" role="alert">
    {{ successMessage }}
  </div>
  <div *ngIf="errorMessage" class="alert alert-danger" role="alert">
    {{ errorMessage }}
  </div>
</form>
