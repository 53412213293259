<!--
  Copyright (C) 2023 Cambridge Epigenetix. All rights reserved.
-->
<!-- <app-landing-hero></app-landing-hero> -->
<div class="container">
    <video
        autoplay
        muted
        loop
        playsinline
        preload="auto"
        class="hero-background position-absolute"
    >
        <source src="../assets/20230309-110108.mp4" type="video/mp4" />
        Your browser does not support HTML5 video.
    </video>

    <div
        class="content text-white text-center d-flex flex-column align-items-center bg-dark-opacity py-5 px-4 rounded-3"
    >
        <img
            src="../assets/alto-logo.png"
            alt="biomodal logo"
            class="logo mb-4"
            style="width: 100%"
        />

        <h2 class="fw-bold text-justify mb-5" style="width: 100%">
            Transforming Bioinformatics Pipelines with Cloud-Based Automation
        </h2>

        <div class="row w-100">
            <div class="col-6 intro">
                <p class="lead mb-3">
                    Say goodbye to the command line and elevate your bioinformatics
                    workflows with unparalleled convenience and efficiency. Alto enables
                    seamless automation of your duet pipelines in the cloud, offering:
                </p>
                <ul class="feature-list ps-3">
                    <li class="mb-2">
                        Seamless automation: Enjoy hassle-free setup and execution of duet
                        pipelines.
                    </li>
                    <li class="mb-2">
                        Exclusive Access: Join a community of experts with invitation-only
                        registration.
                    </li>
                    <li class="mb-2">
                        Integration with CRM: Ensuring smooth user verification for a
                        secure experience.
                    </li>
                </ul>
            </div>
            <div class="col-6">
                <div class="card p-4">
                    <p class="mb-3">
                        If you have received an invitation email, follow the link in the
                        email to register, then log in here:
                    </p>
                    <a class="btn btn-primary fw-bold mb-3" (click)="login()"> Log in </a>
                    <!--Add a small internal login link-->
                    <a class="small text-muted" (click)="internal_login()" style="cursor: pointer;">Internal login</a>
                    <p class="mb-3">
                        If you are interested in duet, please enter your email below and
                        we will be in touch.
                    </p>
                    <div class="input-group mb-3">
                        <input
                            type="text"
                            class="form-control"
                            placeholder="your@email"
                            aria-label="Enter email"
                            aria-describedby="button-addon2"
                        />
                        <button
                            class="btn btn-outline-secondary"
                            type="button"
                            id="button-addon2"
                        >
                            <i class="fas fa-paper-plane"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
