/*
  Copyright (C) 2023 Cambridge Epigenetix. All rights reserved.
*/
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, config, map, of } from 'rxjs';
import { ENV_CONFIG } from '@biomodal-webapps/config';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private apiUrl: string;

  private authStatusSubject = new BehaviorSubject<boolean>(false);
  authStatus$ = this.authStatusSubject.asObservable();

  constructor(@Inject(ENV_CONFIG) private config: { apiUrl: string }, private http: HttpClient) {
    this.apiUrl = config.apiUrl;
  }

  verifyAuthStatus(): Observable<boolean> {
    // This API endpoint simple extract that jwt from the secure cookie
    // and verify it's signature, ensuring the user is still logged in.
    console.log('verifying auth status');
    return this.http
      .get(`${this.apiUrl}/auth/verify`, { withCredentials: true })
      .pipe(
        map((res: any) => {
          console.log('auth status response:', res);
          const isLoggedIn = res.success;
          console.log('is logged in:', isLoggedIn);
          this.authStatusSubject.next(isLoggedIn);
          return isLoggedIn;
        }),
        catchError((err) => {
          this.authStatusSubject.next(false);
          return of(false);
        })
      );
  }

  logout(): void {
    // Clear session storage
    sessionStorage.removeItem('cachedAltoCompany');
    sessionStorage.removeItem('cachedAltoPerson');
    sessionStorage.removeItem('cachedAltoWorkspace');
    sessionStorage.removeItem('currentUserProfile');
  
    // Clear cookies
    this.deleteCookie('email');
    this.deleteCookie('jwt');
    this.deleteCookie('session');
  
    // Set auth status to false
    this.authStatusSubject.next(false);
  
    // Redirect to home page
    window.location.href = '/';
  }
  
  // Utility function to delete a cookie
  private deleteCookie(name: string): void {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  }
  
}
