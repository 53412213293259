import { Component, Input, OnChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgApexchartsModule, ChartComponent } from 'ng-apexcharts';
import { ApexAxisChartSeries, ApexChart, ApexTitleSubtitle, ApexXAxis, ApexYAxis, ApexTooltip } from 'ng-apexcharts';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  tooltip: ApexTooltip;
};

@Component({
  selector: 'biomodal-webapps-generic-scatter',
  standalone: true,
  imports: [CommonModule, NgApexchartsModule],
  templateUrl: './generic-scatter.component.html',
  styleUrls: ['./generic-scatter.component.css'],
})
export class GenericScatterComponent implements OnChanges {
  @Input() title: string = '';
  @Input() description: string = '';
  @Input() numFormat: string = '';
  @Input() data: any[] = [];
  @Input() docLink: string | null = null; 

  public scatterChartOptions: Partial<ChartOptions> = {};

  ngOnChanges(): void {
    if (this.data) {
      this.updateChart();
    }
  }

  updateChart(): void {
    console.log('docLink', this.docLink);

    const chartData = this.data.map((item: any) => ({
      x: item.sample_id,
      y: parseFloat(item.value),
    }));

    this.scatterChartOptions = {
      series: [
        {
          name: this.title,
          data: chartData,
        },
      ],
      chart: {
        height: 350,
        type: 'scatter',
        zoom: {
          enabled: true,
          type: 'xy',
        },
      },
      xaxis: {
        type: 'category',
        title: {
          text: 'Sample ID',
        },
      },
      yaxis: {
        title: {
          text: 'Value',
        },
        labels: {
          formatter: (value: number) => {
            if (this.numFormat === 'percent') {
              return `${(value * 100).toFixed(2)}%`;
            } else {
              return value.toFixed(2);
            }
          }
        }
      },
      tooltip: {
        y: {
          formatter: (value: number) => {
            return this.numFormat === 'percent' ? `${(value * 100).toFixed(15)}%` : value.toFixed(15);
          }
        }
      }
    };
  }
}
