import { ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AltoCompanyOnboardingService, OnboardingStatus } from '@biomodal-webapps/alto-service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'biomodal-webapps-alto-onboarding-console',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './alto-onboarding-console.component.html',
  styleUrl: './alto-onboarding-console.component.css',
})
export class AltoOnboardingConsoleComponent implements OnChanges  {

  @Input() statusUpdates: OnboardingStatus[] = [];
  steps: OnboardingStatus[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['statusUpdates'] && this.statusUpdates) {
      // Assuming statusUpdates is an array of all current statuses to be displayed
      this.steps = [...this.statusUpdates];
    }
  }

  // steps: OnboardingStatus[] = [];
  // private subscription: Subscription = new Subscription();

  // constructor(private onboardingService: AltoCompanyOnboardingService) {}

  // ngOnInit(): void {
  //   this.subscription = this.onboardingService.onboardingStatus$.subscribe(status => {
  //     if (status) {
  //       const existingIndex = this.steps.findIndex(s => s.step === status.step);
  //       if (existingIndex !== -1) {
  //         // Update the existing step with the new status and possibly a new message.
  //         this.steps[existingIndex].status = status.status;
  //         // Optionally, update the message if we want the 'completed' status to modify it.
  //         this.steps[existingIndex].message = status.message;
  //       } else {
  //         // If the step doesn't exist, add it as new.
  //         this.steps.push(status);
  //       }
  //     }
  //   });
  // }
  

  // ngOnDestroy(): void {
  //   if (this.subscription) {
  //     this.subscription.unsubscribe();
  //   }
  // }
}
