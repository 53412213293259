import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageHeroComponent } from '@biomodal-webapps/ui-components';
import { DuetLaunchProgressBarComponent } from "../../components/duet-launcher/progress-bar/duet-launch-progress-bar.component";
import { Router, RouterModule } from '@angular/router';
import { AltoPersonService, WorkspaceService, AltoCompanyService } from '@biomodal-webapps/alto-service';
import { Subject, catchError, filter, of, switchMap, take, takeUntil, tap } from 'rxjs';

@Component({
    selector: 'biomodal-webapps-launch-pipeline-page',
    standalone: true,
    templateUrl: './launch-pipeline-page.component.html',
    styleUrl: './launch-pipeline-page.component.css',
    imports: [CommonModule, RouterModule, ImageHeroComponent, DuetLaunchProgressBarComponent]
})
export class LaunchPipelinePageComponent implements OnInit, OnDestroy{
    
    destroy$: Subject<boolean> = new Subject<boolean>();
    errorMessage: { error: string; message: string; success: boolean } | null = null;
    
    constructor(
        private workspaceService: WorkspaceService,
        private altoPersonService: AltoPersonService,
        private altoCompanyService: AltoCompanyService,
        private router: Router
    ) {}


    ngOnInit(): void {
        this.altoCompanyService.currentCompany$.pipe(
            takeUntil(this.destroy$),
            tap((company) => {
                console.log('LaunchPipelinePage: company changed', company)
            }),
            filter((company) => company !== null),
            /// next we need to do some thing.
            // we should check if the company object has is_multiworkspace == true
            // if it doesn't, we can route to the workspace introduction
            // and pass workspaces[0]
            // if it does, we can route to the workspace selection page
            // and pass the workspaces array
            tap((company) => {
                if (company.is_multiworkspace) {
                    console.log('LaunchPipelinePage: company has multiple workspaces');
                    this.router.navigate(['/launch-duet/workspace-selection'], { state: { workspaces: company.workspaces } });
                } else {
                    console.log('LaunchPipelinePage: company has a single workspace', company.workspaces[0]);
                    this.router.navigate(['/launch-duet/introduction'], { state: { workspace: company.workspaces[0] }});
                }
            }),
            catchError((error) => {
                console.error('LaunchPipelinePage: error getting company', error);
                this.errorMessage = {
                    error: error.error.error,
                    message: error.error.message,
                    success: error.error.success,
                  };
                return of(null); // Return null observable to terminate the pipeline
            })            
        ).subscribe();
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

}
