import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageHeroComponent, WorkspaceListComponent } from '@biomodal-webapps/ui-components';
import {
  AltoCompanyService,
  WorkflowService,
  WorkspaceService,
} from '@biomodal-webapps/alto-service';
import { Subject, catchError, filter, of, switchMap, takeUntil, tap } from 'rxjs';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button'; // If needed for status indicators
import { MenuItem } from 'primeng/api';
import { ContextMenuModule } from 'primeng/contextmenu';
import { Router } from '@angular/router';
import { TagModule } from 'primeng/tag';
import { ChipModule } from 'primeng/chip';

@Component({
    selector: 'biomodal-webapps-my-pipelines-page',
    standalone: true,
    templateUrl: './my-pipelines-page.component.html',
    styleUrl: './my-pipelines-page.component.css',
    imports: [
        CommonModule,
        ImageHeroComponent,
        TableModule,
        ButtonModule,
        ContextMenuModule,
        WorkspaceListComponent,
        TagModule,
        ChipModule,
    ],
    encapsulation: ViewEncapsulation.None,

})
export class MyPipelinesPageComponent implements OnInit {
  private destroy$ = new Subject<void>();
  my_pipelines = [];

  company: any = null;
  workspace: any = null;
  loading = false;
  items: MenuItem[] = [];
  selectedPipeline: any;
  errorMessage: { error: string; message: string; success: boolean } | null = null;

  showWorkspaceList: boolean = false;

  constructor(
    private altoCompanyService: AltoCompanyService,
    private altoWorkspaceService: WorkspaceService,
    private workflowService: WorkflowService,
    private router: Router
  ) {}

  updateContextMenu() {
    this.items = [
      {
        label: 'View',
        icon: 'pi pi-fw pi-eye',
        command: () => this.onView(this.selectedPipeline),
        disabled: this.selectedPipeline.status !== 'SUCCEEDED',
      },
      {
        label: 'Resume',
        icon: 'pi pi-fw pi-play',
        command: () => this.onResume(this.selectedPipeline),
        disabled: this.selectedPipeline.status === 'RUNNING',
      },
      {
        label: 'Relaunch',
        icon: 'pi pi-fw pi-refresh',
        command: () => this.onRelaunch(this.selectedPipeline),
        disabled: this.selectedPipeline.status === 'RUNNING',
      },
      {
        label: 'Delete',
        icon: 'pi pi-fw pi-trash',
        command: () => this.onDelete(this.selectedPipeline),
        disabled: this.selectedPipeline.status === 'RUNNING',
      },
    ];
    console.log(
      'MyPipelinesPageComponent: Updated context menu items',
      this.items
    );
  }

  onContextMenuSelect(event: any) {
    console.log('MyPipelinesPageComponent: Right-clicked row', event.data);
    this.selectedPipeline = event.data; // This ensures we're working with the latest selected row
    this.updateContextMenu(); // Adjust the context menu items based on the selected row
  }

  // Define the action handlers for each context menu action
  onView(workflow: any) {
    /* Handle view action */
    //need to pass the workflow id to the workflow page
    this.router.navigate(['/workflow', workflow.id]);
  }
  onResume(workflow: any) {
    /* Handle resume action */
  }
  onRelaunch(workflow: any) {
    /* Handle relaunch action */
  }
  onDelete(workflow: any) {
    /* Handle delete action */
  }

  ngOnInit(): void {
    this.altoCompanyService.currentCompany$
      .pipe(
        takeUntil(this.destroy$),
        switchMap((company) => {
          console.log('MyPipelinesPageComponent: Got company', company);
          this.company = company;
          if (!company.is_multiworkspace) {
            console.log(
              'MyPipelinesPageComponent: Company is singleworkspace. Fetching workspace'
            );
            return this.altoWorkspaceService
              .fetchCompanyWorkspace(company.id, company.workspaces[0].id);
          } else {
            this.showWorkspaceList = true;
            console.log(
              'MyPipelinesPageComponent: Company is multiworkspace. Showing workspace list', this.showWorkspaceList
            );
            return of(null);  // Return a null observable when multiworkspace
          }
        }),          
        catchError((error) => {
          console.error('MyPipelinesPageComponent: failed to fetch company workspace', error);
          this.errorMessage = {
              error: error.error.error,
              message: error.error.message,
              success: error.error.success,
            };
            return of(null); // Return null to terminate the observable in case of error
          })
      )
      .subscribe();

    this.altoWorkspaceService.currentWorkspace$
      .pipe(
        takeUntil(this.destroy$),
        filter((workspace) => !!workspace),
        tap((workspace) => {
          console.log('MyPipelinesPageComponent: Got workspace', workspace);
          this.workspace = workspace;
          this.fethcWorkflows();
        }),
        catchError((error) => {
          console.error('MyPipelinesPageComponent: failed to fetch company workspace', error);
          this.errorMessage = {
              error: error.error.error,
              message: error.error.message,
              success: error.error.success,
            };
            return of(null); // Return null to terminate the observable in case of error
          })
      )
      .subscribe();
  }

  onWorkspaceSelected(workspace: { id: number, name: string }) {
    console.log('MyPipelinesPageComponent: Selected workspace', workspace);
    this.loading = true;
    this.workspace = workspace;
    this.altoWorkspaceService
      .fetchCompanyWorkspace(this.company.id, workspace.id)
      .pipe(
        takeUntil(this.destroy$),
        tap(() => {
          console.log('MyPipelinesPageComponent: Fetched workspace', workspace);
          this.fethcWorkflows();
        }),
        catchError((error) => {
          console.error('MyPipelinesPageComponent: failed to fetch company workspace', error);
          this.errorMessage = {
              error: error.error.error,
              message: error.error.message,
              success: error.error.success,
            };
            return of(null); // Return null to terminate the observable in case of error
          })
      )
      .subscribe();
  }

  fethcWorkflows() {
    this.loading = true;
    if (this.company && this.workspace) {
      this.workflowService
        .get_workflows_for_workspace(this.company.id, this.workspace.id)
        .pipe(
          takeUntil(this.destroy$),
          tap((response) => {
            console.log('MyPipelinesPageComponent: Got workflows for workspace', response);
            // Assuming response is the object containing the 'data' field with 'workflows' array
            const workflows = response.data.workflows;
            this.my_pipelines = workflows.map((workflow: any) => ({
              id: workflow.id,
              runName: workflow.run_name,
              submittedBy: workflow.submitted_by,
              submittedOn: workflow.submitted_on,
              version: workflow.pipeline_version,
              pipeline: workflow.pipeline_type,
              status: workflow.status, // For visual status indication
            }));
            //SORT WORKFLOWS BY SUBMITTED ON
            this.my_pipelines.sort((a: any, b: any) => {
              return new Date(b.submittedOn).getTime() - new Date(a.submittedOn).getTime();
            });
            console.log(
              'MyPipelinesPageComponent: Got workflows for workspace',
              this.my_pipelines
            );
            this.loading = false;
          }),
          catchError((error) => {
            console.error('MyPipelinesPageComponent: failed to fetch company workspace', error);
            this.errorMessage = {
                error: error.error.error,
                message: error.error.message,
                success: error.error.success,
              };
              return of(null); // Return null to terminate the observable in case of error
            })
        )
        .subscribe();
    }
  }
}
