import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@biomodal-webapps/auth-services';
import { PeopleService, UserProfile } from '@biomodal-webapps/people';
import {
  EMPTY,
  catchError,
  distinctUntilChanged,
  of,
  switchMap,
  take,
  tap,
  throwError,
} from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { SessionStorageService } from '@biomodal-webapps/alto-service';

@Injectable({
  providedIn: 'root',
})
export class AltoAuthOrchestrationService {
  constructor(
    private router: Router,
    private auth: AuthenticationService,
    private peopleService: PeopleService,
    private cookieService: CookieService,
    private sessionStorageService: SessionStorageService
  ) {
    console.log('AltoAuthOrchestrationService: Constructor');

    //Auth status is updated with every route change
    //due to being called by the auth guard.
    this.auth.authStatus$
      .pipe(
        tap((isLoggedIn) => {
          console.log('AltoAuthOrchestrationService: authStatus$', isLoggedIn);
        }),
        switchMap((isLoggedIn) => {
          if (!isLoggedIn) {
            console.log('AltoAuthOrchestrationService: User is not logged in. Do Nothing.');
            return EMPTY; //we'll cancel the chain if the user is not logged in
            // the auth guard will handle the redirect
          }

          console.log('AltoAuthOrchestrationService: User is logged in. Retrieve Profile');
          const sessionProfile = this.sessionStorageService.getItem<UserProfile>('currentUserProfile');
          console.log('AltoAuthOrchestrationService: sessionProfile', sessionProfile);
          //the api stores a cookie with the user's email
          //each time we verify them
          const cookie_email = this.cookieService.get("email");

          if (sessionProfile && sessionProfile.email === cookie_email) {
            // If they match, no need to fetch the profile again
            console.log('AltoAuthOrchestrationService: sessionProfile email matches cookie email. Use sessionProfile', sessionProfile)
            this.peopleService.emitCurrentUser(sessionProfile);
            return of(sessionProfile);
          } else {
            console.log('AltoAuthOrchestrationService: sessionProfile email does not match cookie email. Retrieve from PERSON SERVICE', sessionProfile)
            // If they don't match, clear the session and fetch new profile
            this.peopleService.clearCurrentUserProfile();

            // Get the current user's email to compare
            return this.peopleService.currentUser$.pipe(
              take(1),
              switchMap((currentUser) => {
                if (currentUser && currentUser.email === cookie_email) {
                  // If the emails match, use the current user data and skip fetching from CRM
                  console.log('AltoAuthOrchestrationService: currentUser email matches JWT logged in user. Do not re-retrieve from PERSON SERVICE', currentUser)
                  return of(currentUser);
                } else {
                  // If the emails don't match or their is no current user, proceed to fetch the CRM profile
                  console.log('AltoAuthOrchestrationService: currentUser does not match JWT logged in user email. Retrieve from PERSON SERVICE', currentUser)
                  return this.getPersonProfile();
                }
              })
            );
          }
        }),
      )
      .subscribe()
  }


  getPersonProfile() {
    /*
    This method fetches the user's profile from the CRM and checks if the user has access to ALTO.
    If the user does not have access to ALTO, the user is redirected to the forbidden page.
    If the user does not have a profile in the CRM, the user is redirected to the forbidden page.
    If the user has access to ALTO, the user's profile is returned.
    */

    return this.peopleService.fetchMyProfile().pipe(
      tap((person) => {
        if (!person) {
          console.log('User has logged in, but profile cannot be found. It is possible that the user is not in our CRM.', person);
          throw new Error(
            'User has logged in, but profile cannot be found. It is possible that the user is not in our CRM.'
          );
        }
        if (person.has_alto_access === false) {
          console.log('User has logged in, but does not have access to ALTO.', person);
          throw new Error('User has logged in, but does not have access to ALTO.');
        }
      }),
      // Simulate an error by uncommenting the following SWITCHMAP line
      // switchMap(() => throwError(() => new Error('Simulated fetch error'))),
      catchError((error) => {
        console.error('Failed to fetch user profile', error);
        const errorMessage = error.error.message || "Unknown error occurred"; // Use the API error message if available
        this.router.navigate(['/forbidden'], {
          state: { title: 'Forbidden', message: errorMessage },
        });
        return EMPTY;
      })
    );
  }
}
