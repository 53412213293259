import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  AltoCompanyOnboardingService,
  AltoCompanyService,
  AltoPersonOnboardingService,
  OnboardingStatus,
} from '@biomodal-webapps/alto-service';
import { Subject, filter, takeUntil, tap } from 'rxjs';
import { AltoOnboardingConsoleComponent } from '@biomodal-webapps/ui-components';
import { AltoOnboardingOrchestrationService } from '../../services/onboarding-orchestration-service';

@Component({
    selector: 'alto-onboarding-modal',
    standalone: true,
    templateUrl: './onboarding-modal.component.html',
    styleUrl: './onboarding-modal.component.css',
    imports: [CommonModule, AltoOnboardingConsoleComponent]
})
export class OnboardingModalComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  showModal: boolean = false;
  closeModalButtonEnabled = false;
  onboardingStatuses: OnboardingStatus[] = [];
  error = false;
  
  constructor(
    private altoCompanyOnboardingService: AltoCompanyOnboardingService,
    private altoPersonOnboardingService: AltoPersonOnboardingService,
    private onboardingOrchestrationService: AltoOnboardingOrchestrationService

  ) {}

  ngOnInit(): void {
    console.log('OnboardingModalComponent: NgOnInit');

    this.onboardingOrchestrationService.isOnboarding$
    .pipe(
      takeUntil(this.destroy$),
      tap((isOnboarding) => {
        console.log('OnboardingModalComponent: isOnboarding', isOnboarding);
        if(isOnboarding) {
          this.closeModalButtonEnabled = false;
          this.showModal = true;
        } else {
          this.closeModalButtonEnabled = true;
        }

      })
    ).subscribe();

    this.altoCompanyOnboardingService.onboardingStatus$
      .pipe(
        takeUntil(this.destroy$),
        filter((status): status is OnboardingStatus => status !== null) // Filter out null values
      )
      .subscribe((status) => this.handleOnboardingStatus(status));

    this.altoPersonOnboardingService.onboardingStatus$
      .pipe(
        takeUntil(this.destroy$),
        filter((status): status is OnboardingStatus => status !== null) // Filter out null values
      )
      .subscribe((status) => this.handleOnboardingStatus(status));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  closeModal(): void {
    this.showModal = false;
  }

  handleOnboardingStatus(status: OnboardingStatus) {
    const existingIndex = this.onboardingStatuses.findIndex(
      (s) => s.step === status.step
    );
    if (existingIndex !== -1) {
      // Create a new array for change detection to work properly
      this.onboardingStatuses = this.onboardingStatuses.map((s, index) =>
        index === existingIndex ? { ...s, ...status } : s
      );
    } else {
      // Spread the old statuses and add the new one to ensure a new reference is created
      this.onboardingStatuses = [...this.onboardingStatuses, status];
    }

    if (status.status === 'failed') {
      this.error = true;
      this.closeModalButtonEnabled = true;
    }
  }
}
