<button
pButton
icon="pi pi-code"
class="p-button-secondary"
[disabled]="!isDownloadableFile()"
(click)="showDownloadSnippet('')"
tooltip="Show Download Script"
tooltipPosition="top"
></button>

<p-dialog header="Download File with gcloud" [modal]="true" [(visible)]="snippetDialogVisible" [style]="{ width: '50rem' }">
  <p class="text-dark">To download the file from Google Cloud Storage, use the following command depending on your operating system:</p>

  <!-- Windows Section -->
  <div class="snippet-container">
    <h3 class="text-dark">Windows (CMD)</h3>
    <div class="snippet-wrapper">
      <textarea id="windows-snippet" readonly>{{ this.windowsSnippet }}</textarea>
      <button class="copy-button" (click)="copyToClipboard('windows')">
        <i class="pi pi-clipboard"></i>
      </button>
    </div>
  </div>

  <!-- Mac/Linux Section -->
  <div class="snippet-container">
    <h3 class="text-dark">Mac/Linux</h3>
    <div class="snippet-wrapper">
      <textarea id="mac-snippet" readonly>{{ this.macSnippet }}</textarea>
      <button class="copy-button" (click)="copyToClipboard('mac')">
        <i class="pi pi-clipboard"></i>
      </button>
    </div>
  </div>
</p-dialog>
